import React from 'react';

const AuthUserContext = React.createContext();

export default AuthUserContext;

export const withAuthUser = Component => props => {
    return (
        <AuthUserContext.Consumer>
            {(authUser) => {
                return (
                    <Component {...props} authUser={authUser}></Component>
                );
            }}
        </AuthUserContext.Consumer>
    );
}

export const getConfigWithAuthHeader = async (authUser) => {
    const idToken = await authUser.getIdToken();
    const config = {
        headers: { Authorization: `Bearer ${idToken}` }
    };
    return config;
}