import firebase from 'firebase/app';
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyBxHoKDosV66KPNN2EZrNA3ujOZ2ec2y4U",
    authDomain: "agri-portal.firebaseapp.com",
    databaseURL: "https://agri-portal.firebaseio.com",
    projectId: "agri-portal",
    storageBucket: "agri-portal.appspot.com",
    messagingSenderId: "983442764479"
}

var signInFlow = 'popup';
// For iOS full screen apps we use the redirect auth mode.
if (('standalone' in window.navigator)
    && window.navigator.standalone) {
    signInFlow = 'redirect';
}

export const uiconfig = {
    signInFlow: signInFlow,
    signInSuccessUrl: '/profile',
    signInOptions: [
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            recaptchaParameters: {
                type: 'image',
                size: 'invisible',
                badge: 'bottomright'
            },
            defaultCountry: 'IN'
        },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ]
}

class Firebase {
    constructor() {
        firebase.initializeApp(config);
        this.auth = firebase.auth();
    }

    doSignOut = () => {
        this.auth.signOut();
    };
}

export default Firebase;