import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const style = (theme) => {
    return ({
        progress: {
            margin: theme.spacing.unit * 2
        }
    })
}

class Progress extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                <Grid item>
                    <CircularProgress className={classes.progress} />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(style)(Progress);