import axios from 'axios';
import React from 'react';
import { withRouter } from "react-router";
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getCommodityTitle, getCommodityDescription, getCommodityKeywords, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';
import { withAuthUser } from '../Auth';


class CommodityStates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commodity: null
        }
        const { match } = this.props;
        const { params } = match;
        this.commodityId = params.commodityId;
        this.commodityName = params.commodityName;

    }

    componentDidMount() {
        let commodityResourceUrl;
        if (this.commodityId !== undefined && this.commodityId !== null) {
            commodityResourceUrl = `${BASE_END_POINT}/commodities/${this.commodityId}`;
        } else if (this.commodityName !== undefined && this.commodityName !== null) {
            commodityResourceUrl = `${BASE_END_POINT}/commodityByName/${encodeURIComponent(this.commodityName)}`;
        }

        axios.get(commodityResourceUrl).then(res => {
            this.setState({
                commodity: res.data
            })
        });
    }

    getBreadcrumbs(commodity) {
        if (commodity == null) {
            return [];
        }

        let categoryId = -1;
        let categoryName = 'All';
        if (commodity.category !== undefined && commodity.category !== null) {
            categoryId = commodity.category.id;
            categoryName = commodity.category.name;
        }

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName),
            Breadcrumbs.getCommodityStatesBreadcrumb(categoryId, categoryName, commodity.id, commodity.name)
        ]
        return breadcrumbs;
    }

    render() {

        const mapFunction = (state) => {
            const commodityNameParam = encodeURIComponent(this.state.commodity.name);

            let categoryId = -1;
            let categoryName = 'All';
            let commodity = this.state.commodity;
            if (commodity.category !== undefined && commodity.category !== null) {
                categoryId = commodity.category.id;
                categoryName = commodity.category.name;
            }

            const linkProps = {
                to: Breadcrumbs.getCommodityStateLink(categoryId, categoryName, this.state.commodity.id, commodityNameParam, state.id, state.name),
                label: state.name
            }
            return <NavItem linkProps={linkProps} key={state.id} />
        };
        const breadcrumbs = this.getBreadcrumbs(this.state.commodity);
        let commodityName = null;
        let resourceUrl = null;
        if (this.state.commodity !== null) {
            commodityName = this.state.commodity.name;
            resourceUrl = `${BASE_END_POINT}/commodity-market/commodity-id/${this.state.commodity.id}/states`;
        }
        return (
            <div>
                <Helmet>
                    <title>{getCommodityTitle(commodityName)}</title>
                    <meta name={META_DESCRIPTION} content={getCommodityDescription(commodityName)} />
                    <meta name={META_KEYWORDS} content={getCommodityKeywords(commodityName)} />
                </Helmet>
                <NavCollection resourceUrl={resourceUrl} mapFunction={mapFunction} breadcrumbs={breadcrumbs} />
            </div>
        );
    }
}

export default withAuthUser(withRouter(CommodityStates));