import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BASE_END_POINT } from '../utils/APIConstants'
import axios from 'axios';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from '../Nav';
import { Helmet, getCommodityTitle, getCommodityDescription, getCommodityKeywords } from "../utils/SEOUtils";

class MarketCommodity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commodity: null,
            market: null
        };
    }

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        const { commodityId, marketId } = params;

        let commodityResourceUrl = `${BASE_END_POINT}/commodities/${commodityId}`;
        let marketResourceUrl = `${BASE_END_POINT}/markets/${marketId}`;

        const getCommodity = () => axios.get(commodityResourceUrl);
        const getMarket = () => axios.get(marketResourceUrl);

        axios.all([getCommodity(), getMarket()])
            .then(axios.spread((commodityRes, marketRes) => {
                this.setState({
                    commodity: commodityRes.data,
                    market: marketRes.data
                });
            }));

    }

    getBreadcrumbs(commodity, market) {
        if (market === null || commodity === null) {
            return [];
        }
        const district = market.district;
        const state = district.state;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getStatesBreadcrumb(),
            Breadcrumbs.getStateBreadcrumb(state.id, state.name),
            Breadcrumbs.getDistrictBreadcrumb(district.id, state.name, district.name),
            Breadcrumbs.getMarketBreadcrumb(market.id, state.name, district.name, market.name),
            Breadcrumbs.getMarketCommodityBreadcrumb(market.id, commodity.id, commodity.name, state.name, district.name, market.name),
        ];

        return breadcrumbs;
    }


    render() {
        const { match } = this.props;
        const { commodityId, marketId } = match.params;
        const { commodity, market } = this.state;

        const resourceUrl = `${BASE_END_POINT}/variety-markets/commodity-id/${commodityId}/market-id/${marketId}`;

        const mapFunction = (varietyMarket) => {
            const linkProps = {
                to: urlFunction(varietyMarket),
                label: varietyMarket.variety.name
            };

            return <NavItem linkProps={linkProps} key={varietyMarket.variety.id} />
        }

        const nodataUrlFunction = () => {
            if (commodity === null || market === null) {
                return undefined;
            }
            const district = market.district;
            const state = district.state;

            return Breadcrumbs.getCommodityMarketPriceLink(commodity.id, commodity.name, market.id, state.name, district.name, market.name);
        }

        const urlFunction = (varietyMarket) => {
            const variety = varietyMarket.variety;
            const commodity = variety.commodity;
            const market = varietyMarket.market;
            const district = market.district;
            const state = district.state;
            return Breadcrumbs.getMarketVarietyPriceLink(variety.id, commodity.name, variety.name, market.id, state.name, district.name, market.name);
        }

        const breadcrumbs = this.getBreadcrumbs(this.state.commodity, this.state.market);
        let commodityName = '', marketName = '', districtName = '', stateName = '';
        if (commodity !== null) {
            commodityName = commodity.name;
        }
        if (market !== null) {
            marketName = market.name;
            districtName = market.district.name;
            stateName = market.district.state.name;
        }

        return (
            <div>
                <Helmet>
                    <title>{getCommodityTitle(commodityName, marketName, districtName, stateName)}</title>
                    <meta name="description" content={getCommodityDescription(commodityName, marketName, districtName, stateName)} />
                    <meta name="keywords" content={getCommodityKeywords(commodityName, marketName, districtName, stateName)} />
                </Helmet>
                <NavCollection resourceUrl={resourceUrl} breadcrumbs={breadcrumbs} mapFunction={mapFunction} urlFunction={urlFunction} nodataUrlFunction={nodataUrlFunction} />
            </div>
        );

    }
}

export default withRouter(MarketCommodity);