import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getTitle, getDescription, getKeywords } from '../utils/SEOUtils';

class State extends Component {
    componentDidMount() {
        //  TODO: Revisit this and implement similar functionality in all of the navigation components.
        window.scrollTo(0, 0);
    }

    render() {
        const { match } = this.props;
        const { stateId, stateName } = match.params;
        const resourceUrl = `${BASE_END_POINT}/state-id/${stateId}/districts`;
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getStatesBreadcrumb(),
            Breadcrumbs.getStateBreadcrumb(stateId, stateName)
        ];

        const mapFunction = (district) => {
            const linkProps = {
                to: Breadcrumbs.getDistrictLink(district.id, stateName, district.name),
                label: district.name
            }
            return (
                <NavItem linkProps={linkProps} key={district.id} />
            )
        };

        return (
            <div>
                <Helmet>
                    <title>{getTitle(stateName)}</title>
                    <meta name="description" content={getDescription(stateName)} />
                    <meta name="keywords" content={getKeywords(stateName)} />
                </Helmet>
                <NavCollection breadcrumbs={breadcrumbs} resourceUrl={resourceUrl} mapFunction={mapFunction} />
            </div>
        );
    }
}

export default withRouter(State);