import React from 'react';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";


class Commodities extends React.Component {
    getBreadcrumbs() {
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCommoditiesBreadcrumb()
        ];
        return breadcrumbs;
    }

    render() {
        const commodityResourceUrl = `${BASE_END_POINT}/commodities`;
        const breadcrumbs = this.getBreadcrumbs();
        const mapFunction = (commodity) => {
            const commodityNameParam = encodeURIComponent(commodity.name);
            const linkProps = {
                to: Breadcrumbs.getCommodityStatesLink(commodity.id, commodityNameParam),
                label: commodity.name
            };
            return <NavItem linkProps={linkProps} key={commodity.id} />
        }

        return (
            <NavCollection breadcrumbs={breadcrumbs} resourceUrl={commodityResourceUrl} mapFunction={mapFunction} />
        );
    }
}

export default Commodities;