import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import { BASE_END_POINT } from "../utils/APIConstants";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import EditIcon from '@material-ui/icons/Edit';

const styles = (theme) => {
    return (
        {
            layout: {
                width: 'auto',
                marginLeft: theme.spacing.unit * 2,
                marginRight: theme.spacing.unit * 2,
                [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
                    width: 600,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            }
        }
    );
}

class SellAdDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sellAd: null
        }
    }
    componentDidMount() {
        const { match } = this.props;
        const { sellAdId } = match.params;
        const resourceUrl = `${BASE_END_POINT}/sell-ads/${sellAdId}`;
        axios.get(resourceUrl).then(res => {
            console.log(res.data);
            this.setState({
                sellAd: res.data,
                loading: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleEdit = () => {
        const { history } = this.props;
        const { match } = this.props;
        const { sellAdId } = match.params;
        history.push(Breadcrumbs.getSellAdEditLink(sellAdId));
    }
    render() {

        const { classes } = this.props;
        return (
            this.state.loading ? null :
                <Grid container direction={"column"} spacing={16} className={classes.layout}>
                    <Grid item style={{ alignSelf: "center" }}>
                        <Grid container>
                            <Grid item>
                                <Typography variant={"h5"} gutterBottom>Your Commodity Listing</Typography>
                            </Grid>
                            <Grid item style={{ alignSelf: "right" }}>
                                <IconButton aria-label="Edit" style={{ padding: 5 }} onClick={this.handleEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography variant={"h6"}>{`Commodity`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"}>{`${this.state.sellAd.variety.commodity.name}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography variant={"h6"}>{`Variety`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"}>{`${this.state.sellAd.variety.name}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item >
                                <Typography variant={"h6"}>{`Nearest Market`}</Typography>
                            </Grid>
                            <Grid item >
                                <Typography variant={"body1"}>{`${this.state.sellAd.market.name} [${this.state.sellAd.market.district.name}, ${this.state.sellAd.market.district.state.name}]`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>{`Quantity`}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"body1"}>{`${this.state.sellAd.quantity}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item >
                                <Typography variant={"h6"}>{`Price`}</Typography>
                            </Grid>
                            <Grid item >
                                <Typography variant={"body1"}>{`${this.state.sellAd.price}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        this.state.sellAd.description == null
                            ? null
                            :
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item >
                                        <Typography variant={"h6"}>{`Description`}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant={"body1"}>{`${this.state.sellAd.description}`}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                    <Grid item>
                        <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth onClick={this.handleEdit}>{'Edit'}</Button>
                    </Grid>
                    {/** 
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography variant={"h6"}>{`Name`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"}>{`${this.state.sellAd.user.firstName}`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <Typography variant={"h6"}>{`Phone Number`}</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"}>{`View`}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    */}
                </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(SellAdDetail));