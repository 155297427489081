import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from './Breadcrumbs';
import Axios from '../../axios';
import PropTypes from "prop-types";
import { Progress } from '../utils';
import { withRouter } from 'react-router';
import Redirect from './Redirect';

class NavCollection extends Component {
    render() {
        const { breadcrumbs, resourceUrl, mapFunction, nodataUrlFunction } = this.props;
        // const { urlFunction } = this.props;
        return (
            <Grid container direction={'column'} spacing={16}>
                <Grid item>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                </Grid>
                <Grid item >
                    {resourceUrl !== null ?
                        <Axios url={resourceUrl}>
                            {({ data = [], loading, dataLoaded }) => {
                                if (data.length === 0 && dataLoaded === true) {
                                    if (nodataUrlFunction !== undefined) {
                                        const url = nodataUrlFunction();
                                        // console.log(`Data Length: ${data.length}. Redirecting to: ${url}`);
                                        return <Redirect url={url} />
                                    }
                                }
                                /*
                                // As this behaviour can be confusing reenable it after looking at user experience.
                                if (data.length === 1) {
                                    if (urlFunction !== undefined) {
                                        const url = urlFunction(data[0]);
                                        console.log(`Data Length: ${data.length}. Redirecting to: ${url}`);
                                        return <Redirect url={url} />
                                    }
                                }
                                */
                                if (loading) {
                                    return <Progress />
                                } else {
                                    return data.map(mapFunction)
                                }
                            }
                            }
                        </Axios>
                        :
                        null
                    }
                </Grid>
            </Grid>
        );
    }
}

NavCollection.propTypes = {
    breadcrumbs: PropTypes.array.isRequired,
    resourceUrl: PropTypes.string,
    mapFunction: PropTypes.func.isRequired
}

export default withRouter(NavCollection);