import { Card, CardContent, CircularProgress, Grid, Tab, Tabs, Typography }
    from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from "react-router";
// import { BASE_END_POINT } from '../../APIConstants';
import Axios from '../../axios';
import axios from 'axios';
import { withAuthUser, getConfigWithAuthHeader } from '../Auth';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { Progress } from '../utils';
import CommodityPriceChart from './CommodityPriceChart';
import CommodityPriceTable from './CommodityPriceTable';
import { incrementSessionViews, getTotalViewsInThisSession, resetSessionViews, TOTAL_NO_OF_FREE_VIEWS, TOTAL_NO_OF_FREE_SESSION_VIEWS } from '../utils/SubscriptionUtils';
import { BASE_END_POINT, AUTH_STATE_NOT_YET_LOADED, AUTH_STATE_NOT_SIGNED_IN } from '../utils/APIConstants';
import { AdSlot } from '../utils';


const styles = theme => ({
    priceHeader: {
        marginLeft: theme.spacing.unit * 2
    }
});

class CommodityPrice extends Component {
    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.state = {
            tabValue: 0,
            days: 7,
        }
        this.subscriptionStatusChecked = false;
        this.userData = null;
    }

    checkSubscription = async () => {
        const { loading } = this.props;
        const { history, authUser } = this.props;
        if (authUser === AUTH_STATE_NOT_YET_LOADED) {
            return;
        }

        if (authUser !== AUTH_STATE_NOT_SIGNED_IN) {
            await this.loadCurrentUserDetails();
        }

        if (loading) {
            return;
        }
        const totalViewsInThisSession = getTotalViewsInThisSession();

        if (totalViewsInThisSession > TOTAL_NO_OF_FREE_SESSION_VIEWS) {
            if (authUser === AUTH_STATE_NOT_SIGNED_IN) {
                history.push(Breadcrumbs.getSignInLink());
                return;
            }

            const totalViews = await this.getNoOfViews();
            if (totalViews > TOTAL_NO_OF_FREE_VIEWS) {
                let subscriptionStatus = this.userData.subscriptionStatus;
                const activeStates = ['UNCONFIRMED', 'ACTIVE'];
                const activeSubscription = activeStates.includes(subscriptionStatus);
                if (!activeSubscription) {
                    history.push(Breadcrumbs.getSubscriptionLink());
                    return;
                }
            }
        }
    }

    async loadCurrentUserDetails() {
        if (this.userData != null) {
            // Data has already been loaded.
            return;
        }
        const config = await getConfigWithAuthHeader(this.props.authUser);
        const url = `${BASE_END_POINT}/users/currentUserWithAttributes`;
        const response = await axios.get(url, config);
        this.userData = response.data;
    }

    async incrementNoOfViews() {
        const config = await getConfigWithAuthHeader(this.props.authUser);
        const url = `${BASE_END_POINT}/users/incrementNoOfViews`;
        const formData = {};
        await axios.post(url, formData, config);
    }

    async getNoOfViews() {
        const config = await getConfigWithAuthHeader(this.props.authUser);
        const url = `${BASE_END_POINT}/users/currentUserNoOfViews`;
        const response = await axios.get(url, config);
        return response.data;
    }

    async checkAuthAndincrementNoOfViews() {
        const { authUser } = this.props;
        if (authUser === AUTH_STATE_NOT_YET_LOADED || this.userData === null) {
            return;
        }
        await this.incrementNoOfViews();
    }

    componentDidMount() {
        this.checkSubscription();
        incrementSessionViews();
        this.checkAuthAndincrementNoOfViews();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkSubscription();
        if (prevProps.resourceUrl !== null && prevProps.resourceUrl !== this.props.resourceUrl) {
            incrementSessionViews();
            this.checkAuthAndincrementNoOfViews();
        }
        window.scrollTo(0, 0);
    }

    render() {
        const { loading, history, authUser, resourceUrl, title, variety } = this.props;

        let singleVariety = false;
        if (variety !== undefined) {
            singleVariety = true;
        }
        if (loading === true) {
            return (
                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                    <Grid item>
                        <CircularProgress className={this.classes.progress} />
                    </Grid>
                </Grid>
            )
        }
        return (
            <Axios url={`${resourceUrl}?size=${this.state.days}`}>
                {
                    ({ data = [], dataLoaded, loading }) => {
                        if (dataLoaded === false || loading === true) {
                            return (
                                <Progress />
                            )
                        }
                        let latestData = null;
                        let latestDataForAllVarieties = null;
                        if (data.length > 0) {
                            latestData = data[data.length - 1];
                            latestDataForAllVarieties = data.filter(dataRow => dataRow.arrivalDate === latestData.arrivalDate);
                        }
                        return (
                            <Grid container direction={"column"}>
                                <Grid item style={{ alignSelf: 'center' }}>
                                    <Typography variant={'h6'} >{title}</Typography>
                                </Grid>
                                <Grid item>
                                    {latestDataForAllVarieties !== null ?
                                        latestDataForAllVarieties.map(dataRow => {
                                            return (<Card key={`${dataRow.variety.name}-${dataRow.arrivalDate}`}>
                                                <CardContent>
                                                    <Typography variant={"h6"} component={"h6"}>
                                                        {dataRow.variety.name}
                                                    </Typography>
                                                    <Typography variant={"caption"}>
                                                        {dataRow.arrivalDate}
                                                    </Typography>
                                                    <Typography variant={"body1"}>
                                                        {`Range: [${dataRow.minPrice.toLocaleString()} - ${dataRow.maxPrice.toLocaleString()}]`}
                                                    </Typography>
                                                    <Typography variant={"body1"}>
                                                        {`Modal: ${dataRow.modalPrice.toLocaleString()}`}
                                                    </Typography>
                                                </CardContent>
                                            </Card>)
                                        }
                                        )
                                        : null}
                                </Grid>
                                {/** 
                                <Grid item style={{ paddingTop: 16 }}>
                                    <AdSlot slot='4716727870' responsive='true' key={resourceUrl} slotLocation={`CommodityPrice-AfterPriceCard`} />
                                </Grid>
                                */}
                                <Grid item style={{ alignSelf: 'center' }}>
                                    <Typography variant={'h6'} >{'Trend'}</Typography>
                                </Grid>
                                <Grid item>
                                    <Tabs value={this.state.tabValue} onChange={
                                        (e, newValue) => {
                                            let days = 7;
                                            switch (newValue) {
                                                case 0:
                                                    days = 7;
                                                    break;
                                                case 1:
                                                    days = 30;
                                                    break;
                                                case 2:
                                                    days = 60;
                                                    break;
                                                default:
                                                    days = 7;
                                            }
                                            if (days > 7 && (authUser === AUTH_STATE_NOT_SIGNED_IN
                                                || authUser === AUTH_STATE_NOT_YET_LOADED)) {
                                                history.push(Breadcrumbs.getSignInLink());
                                                return;
                                            }
                                            this.setState({ tabValue: newValue, days: days });
                                        }} centered>
                                        <Tab label="7 Days" />
                                        <Tab label="30 Days" />
                                        <Tab label="60 Days" />
                                    </Tabs>
                                </Grid>
                                <Grid item>
                                    {data.length === 0 ?
                                        <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                                            <Grid item>
                                                <div>No matching records found.</div>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container direction={'row'} alignItems={'center'} justify={'center'}>
                                            <Grid item md={12}>
                                                <Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                                                    <Grid item>
                                                        <CommodityPriceChart data={data} />
                                                    </Grid>
                                                    <Grid item>
                                                        <CommodityPriceTable data={data} hideVarietyName={singleVariety} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        );
                    }
                }
            </Axios>
        );
    }
}

CommodityPrice.propTypes = {
    classes: PropTypes.object.isRequired,
    market: PropTypes.object,
    commodity: PropTypes.object
}

export default withStyles(styles)(withAuthUser(withRouter(CommodityPrice)));;