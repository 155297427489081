import React from 'react';
import { Progress } from '../utils';
import { withRouter } from 'react-router';

class Redirect extends React.Component {

    componentDidMount() {
        const { url, history } = this.props;
        history.push(url);
    }

    // Show the progress bar until redirection.
    render() {
        return <Progress />
    }
}

export default withRouter(Redirect);