import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getCommodityTitle, getCommodityDescription, getCommodityKeywords, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';

class CommodityDistrict extends Component {

    render() {
        const { match } = this.props;
        const { stateId, stateName, districtId, districtName, categoryId, categoryName, commodityId, commodityName } = match.params;

        const resourceUrl = `${BASE_END_POINT}/commodity-market/commodity-id/${commodityId}/district-id/${districtId}/markets`;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName),
            Breadcrumbs.getCommodityStatesBreadcrumb(categoryId, categoryName, commodityId, commodityName),
            Breadcrumbs.getCommodityStateBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName),
            Breadcrumbs.getCommodityDistrictBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName)
        ];

        const mapFunction = (market) => {
            const linkProps = {
                to: urlFunction(market),
                label: market.name
            };
            return <NavItem linkProps={linkProps} key={market.id} />
        }

        const urlFunction = (market) => {
            return Breadcrumbs.getCommodityMarketLink(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName, market.id, market.name);
        }

        return (
            <div>
                <Helmet>
                    <title>{getCommodityTitle(commodityName, districtName, stateName)}</title>
                    <meta name={META_DESCRIPTION} content={getCommodityDescription(commodityName, districtName, stateName)} />
                    <meta name={META_KEYWORDS} content={getCommodityKeywords(commodityName, districtName, stateName)} />
                </Helmet>
                <NavCollection resourceUrl={resourceUrl} breadcrumbs={breadcrumbs} mapFunction={mapFunction} urlFunction={urlFunction} />
            </div>
        );
    }
}

export default withRouter(CommodityDistrict);