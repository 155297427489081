import { CssBaseline, Grid, Typography } from "@material-ui/core";
import { amber, green } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from "react-ga";
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router";
import { Link as RouterLink, Route, Switch } from "react-router-dom";
import AnvayinAppBar from './AnvayinAppBar';
import AnvayinDrawer from './AnvayinDrawer';
import './App.css';
import { Profile, SignIn, Subscription, withFirebase } from './components/Auth';
import AuthUserContext from "./components/Auth/AuthUserContext";
import { Categories, CategoryCommodities, Commodities, CommodityDistrict, CommodityMarket, CommodityState, CommodityStates, VarietyDistrict, VarietyState, VarietyStates } from './components/Commodity';
import { CommodityMarketPrice } from './components/CommodityMarketPrice';
import { District, Market, MarketCommodity, State, States } from './components/Market';
import { MarketCommodityPrice } from './components/MarketCommodityPrice';
import { MarketVarietyPrice } from './components/MarketVarietyPrice';
import { AboutUs, PrivacyPolicy, TermsAndConditions } from "./components/misc";
import { PaymentResponse } from './components/Payment';
import { SellAd, SellAdDetail, SellAdUpdate } from "./components/SellAd";
import { AdSlot, ErrorBoundry } from './components/utils';
import { AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED, BASE_END_POINT } from './components/utils/APIConstants';
import { BASE_DESCRIPTION, BASE_KEYWORDS, BASE_ROBOTS, BASE_TITLE } from './components/utils/SEOUtils';
import { getPhoneNoWithoutCountryCode, resetSessionViews } from './components/utils/SubscriptionUtils';
import { VarietyMarketPrice } from './components/VarietyMarketPrice';
import Contact from './Contact';
import Home from './Home';

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    minWidth: '100vW'
  },
  toolbar: theme.mixins.toolbar,
  mainContent: {
    paddingTop: 24,
    minHeight: '90vh',
    minWidth: '90vw'
  },
  footerLink: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText
  }
});

class App extends Component {

  state = {
    open: false,
    authUser: AUTH_STATE_NOT_YET_LOADED
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleSignOut = () => {
    this.props.firebase.doSignOut();
    this.handleDrawerClose();
  }

  componentDidMount() {
    const { firebase } = this.props;
    this.unregisterAuthStateObserver = firebase.auth.onAuthStateChanged(
      user => {
        user ? this.setState({ authUser: user }) : this.setState({ authUser: AUTH_STATE_NOT_SIGNED_IN });
        if (user) {
          this.saveUserToAnvRepository(user);
          resetSessionViews();
          ReactGA.set({
            userId: user.uid
          })
        }
      }
    );
  }

  saveUserToAnvRepository(user) {
    const url = `${BASE_END_POINT}/users/firebaseUser`;
    const phoneNumber = getPhoneNoWithoutCountryCode(user.phoneNumber);
    axios.post(url, {
      email: user.email,
      phoneNumber: phoneNumber,
      uid: user.uid
    }).then(() => {
      // console.log(res);
    }).catch(() => {
      // console.log(error);
    });
  }

  componentWillUnmount() {
    this.unregisterAuthStateObserver();
  }

  render() {
    const currentTime = Date.now();

    const theme = createMuiTheme({
      palette: {
        primary: green,
        secondary: amber
      },
      typography: {
        useNextVariants: true
      }
    });

    const { classes } = this.props;
    const { open } = this.state;
    const { location } = this.props;

    // /commodity/Turmeric/market/Erode (/commodity/:commodityName/market/:marketName)
    const commodityMarketNameRegEx = /^\/commodity\/(?:([^/]+?))\/market\/(?:([^/]+?))\/?$/i;
    // /commodity/:commodityName/state/:stateName/district/:districtName/market/:marketName
    const commodityFullMarketRegEx = /^\/commodity\/(?:([^/]+?))\/state\/(?:([^/]+?))\/district\/(?:([^/]+?))\/market\/(?:([^/]+?))\/?$/i;
    const profileRegEx = /^\/profile\/?$/i;
    const signInRegEx = /^\/signIn\/?$/i;
    const subscriptionRegEx = /^\/subscription\/?$/i;
    const contactRegEx = /^\/contact\/?$/i;
    const sellAdRegEx = /^\/sell\-ad\/?$/i;
    const sellAdDetailRegEx = /^\/sell-ad\/(?:([^\/]+?))\/?$/i;
    const sellAdEditRedEx = /^\/sell-ad\/(?:([^\/]+?))\/edit\/?$/i;


    const pathName = location.pathname;
    // TODO: Don't show ads that meet the following criteria
    const dontShowFooterAds = (pathName.indexOf('price') > -1)
      || commodityMarketNameRegEx.test(pathName)
      || commodityFullMarketRegEx.test(pathName)
      || profileRegEx.test(pathName)
      || signInRegEx.test(pathName)
      || subscriptionRegEx.test(pathName)
      || contactRegEx.test(pathName)
      || sellAdRegEx.test(pathName)
      || sellAdDetailRegEx.test(pathName)
      || sellAdEditRedEx.test(pathName);

    return (
      <MuiThemeProvider theme={theme}>
        <AuthUserContext.Provider value={this.state.authUser}>
          <div className={classes.root}>
            <Helmet>
              <title>{BASE_TITLE}</title>
              <meta name="description" content={BASE_DESCRIPTION} />
              <meta name="keywords" content={BASE_KEYWORDS} />
              <meta name="robots" content={BASE_ROBOTS} />
            </Helmet>
            <CssBaseline />
            <div style={{ minHeight: "100vh", width: "100%" }}>
              <AnvayinAppBar open={open} openHandler={this.handleDrawerOpen}></AnvayinAppBar>
              <div className={classes.toolbar} />
              <AnvayinDrawer open={open} handleDrawerClose={this.handleDrawerClose} handleSignOut={this.handleSignOut} />
              {/*
              When Search Engines send traffic to the server, forward all of the responses to the same html page that contains the react loading.
             */}
              <Grid container spacing={32} alignItems={"center"} justify={"space-evenly"} className={classNames({ [classes.mainContent]: true })}>
                <Grid item xs={12} style={{ alignSelf: "flex-start" }}>
                  <ErrorBoundry>

                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/contact" exact component={Contact} />

                      <Route path="/commodity/:commodityName.htm" exact component={CommodityStates} />
                      <Route path="/commodity/:commodityName" exact component={CommodityStates} />
                      <Route path="/market/:marketName.htm" exact component={Market} />
                      <Route path="/market/:marketName" exact component={Market} />

                      <Route path="/commodity/:commodityName/market/:marketName.htm" exact component={MarketCommodityPrice} />
                      <Route path="/commodity/:commodityName/market/:marketName" exact component={MarketCommodityPrice} />

                      <Route path="/commodity/:commodityName/state/:stateName/district/:districtName/market/:marketName.htm" exact component={MarketCommodityPrice} />
                      <Route path="/commodity/:commodityName/state/:stateName/district/:districtName/market/:marketName" exact component={MarketCommodityPrice} />

                      <Route path="/price/:commodityId/:commodityName/:marketId/:stateName/:districtName/:marketName" exact component={MarketCommodityPrice} />

                      <Route path="/states" exact component={States} />
                      <Route path="/state-id/:stateId/state/:stateName" exact component={State} />
                      <Route path="/district-id/:districtId/state/:stateName/district/:districtName" exact component={District} />
                      <Route path="/market-id/:marketId/state/:stateName/district/:districtName/market/:marketName" component={Market} />
                      <Route path="/market-commodity/:marketId/:commodityId/*" component={MarketCommodity} />
                      <Route path="/market-variety-price/:varietyId/:marketId/:commodityName/:varietyName/*" component={MarketVarietyPrice} />
                      {/* 
                    :stateName/:districtName/:marketName/:commodityName
                    */}

                      <Route path="/categories" exact component={Categories} />
                      <Route path="/commodities" exact component={Commodities} />
                      <Route path="/category-commodity/:categoryId/:categoryName/commodities" exact component={CategoryCommodities} />
                      <Route path="/commodity-market/:categoryId/:categoryName/:commodityId/:commodityName/states" exact component={CommodityStates} />
                      <Route path="/commodity-market/:categoryId/:categoryName/:commodityId/:commodityName/:stateId/:stateName/districts" exact component={CommodityState} />
                      <Route path="/commodity-market/:categoryId/:categoryName/:commodityId/:commodityName/:stateId/:stateName/:districtId/:districtName" exact component={CommodityDistrict} />
                      <Route path="/commodity-market/:categoryId/:categoryName/:commodityId/:commodityName/:stateId/:stateName/:districtId/:districtName/:marketId/:marketName" exact component={CommodityMarket} />
                      <Route path="/commodity-market-price/:commodityId/:commodityName/:marketId/*" component={CommodityMarketPrice} />
                      <Route path="/variety-market/:varietyId/:commodityName/:varietyName/states" exact component={VarietyStates} />
                      <Route path="/variety-market/:varietyId/:commodityName/:varietyName/:stateId/:stateName/districts" exact component={VarietyState} />
                      <Route path="/variety-market/:varietyId/:commodityName/:varietyName/:districtId/:stateName/:districtName/markets" exact component={VarietyDistrict} />

                      <Route path="/variety-market-price/:varietyId/:marketId/:commodityName/:varietyName/*" component={VarietyMarketPrice} />

                      <Route path="/sell-ad" exact component={SellAd} />
                      <Route path="/sell-ad/new" exact component={SellAdUpdate} />
                      <Route path="/sell-ad/:sellAdId" exact component={SellAdDetail} />
                      <Route path="/sell-ad/:sellAdId/edit" exact component={SellAdUpdate} />

                      <Route path='/signIn' exact component={SignIn} />

                      <Route path="/profile" exact component={Profile} />

                      <Route path='/subscription' exact component={Subscription} />

                      <Route path='/paymentResponse/' exact component={PaymentResponse} />

                      <Route path="/termsAndConditions" exact component={TermsAndConditions} />
                      <Route path="/termsConditions.htm" exact component={TermsAndConditions} />

                      <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
                      <Route path="/privacyPolicy.htm" exact component={PrivacyPolicy} />

                      <Route path="/aboutUs" exact component={AboutUs} />
                      <Route path="/about.htm" exact component={AboutUs} />

                      {/*
                      Default Route to handle non existing routes.
                    */}
                      <Route component={States} />
                    </Switch>
                  </ErrorBoundry>
                </Grid>

                {/* Display footer Ad in pages other than price page. */}

                {
                  dontShowFooterAds ?
                    null :
                    <Grid item xs={12} style={{ alignSelf: "flex-end" }} >
                      <AdSlot slot='7313724213' responsive='true' key={`homePage-${currentTime}`} slotLocation={`Global-Above-Footer`} />
                    </Grid>
                }

                <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
                  <Grid container alignItems={"stretch"} justify={"center"} spacing={8}>
                    <Grid item>
                      <Typography variant={"body2"}>© 2021 Anvayin</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={"body2"}>|</Typography>
                    </Grid>
                    <Grid item>
                      <RouterLink to="/termsAndConditions" className={classes.footerLink}>
                        <Typography variant={"body2"}>Terms And Conditions</Typography>
                      </RouterLink>
                    </Grid>
                    <Grid item>
                      <Typography variant={"body2"}>|</Typography>
                    </Grid>
                    <Grid item>
                      <RouterLink to={'/privacyPolicy'} className={classes.footerLink}>
                        <Typography variant={"body2"}>Privacy Policy</Typography>
                      </RouterLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </AuthUserContext.Provider>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withFirebase(withRouter(App)));