import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { Link as RouterLink } from "react-router-dom";

const drawerWidth = 240;
const IconButtonWidth = 72;

const styles = theme => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    toolbar: theme.mixins.toolbar,
    appBarTypography: {
        flexGrow: 1,
        marginRight: IconButtonWidth
    }
});

function AnvayinAppBar(props) {
    const { classes, open, openHandler, theme } = props;
    return (
        <AppBar position="fixed"
            className={classNames(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar disableGutters={!open}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={openHandler}
                    className={classNames({ [classes.menuButton]: true, [classes.hide]: open })}
                >
                    <MenuIcon />
                </IconButton>

                <Grid item className={classNames(classes.appBarTypography)}>
                    <Grid item>
                        <RouterLink to="/" style={{ textDecoration: "none", color: theme.palette.primary.contrastText }}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Grid item style={{marginBottom:5}}>
                                    <img src="/logo.png" width={25} align="center"  alt={'Logo'} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" color="inherit" noWrap align="center">
                                        Anvayin Commodities
                                    </Typography>
                                </Grid>
                            </Grid>
                        </RouterLink>

                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

AnvayinAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    openHandler: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(AnvayinAppBar);