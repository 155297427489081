import React, { Component } from 'react';
import Proptypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Chart } from 'react-google-charts';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2
    }
});

const options = {
    curveType: "function",
    legend: { position: "bottom" },
    lineWidth: 3,
    interpolateNulls: true,
    hAxis: {
        format: 'dd/MMM'
    }
};

const chartEvents = [
    {
        eventName: 'error',
        callback({ eventArgs }) {
            console.log("Error Occurred", eventArgs[0].message);
        }
    }
];

class CommodityPriceChart extends Component {

    render() {
        const {data, classes} = this.props;
        let chartData = this.buildChatData(data);
        // TODO: Change the Chart to be material Line Chart (i.e. ChartType = Line ) once all the options are supported.
        // Currently we use curveType. If this option and legend positon are supported we can switch to material charts 
        // that use Material Design.
        // https://react-google-charts.com/line-chart#material-design-linechart 
        // Still unsupported options: https://github.com/google/google-visualization-issues/issues/2143

        return (
            <Grid container direction={'column'} justify={'center'} alignItems={'stretch'} spacing={16}>
                <Grid item xs={12}>
                    {chartData.length > 0 ?
                        <Chart chartType="LineChart" width="100%" height="400px"
                            data={chartData} options={options} chartEvents={chartEvents}
                            loader={<Grid container direction={'column'} alignItems={'center'} justify={'center'}>
                                <Grid item>
                                    <CircularProgress className={classes.progress} />
                                </Grid>
                            </Grid>
                            } />
                        :
                        <div>No matching records found.</div>
                    }
                </Grid>
            </Grid>
        );
    }

    buildChatData(jsonData) {
        // Sample Data
        // First row is columns row 
        // The following rows are data rows.
        /*
        this.data = [
            ["Year", "Sales", "Expenses"],
            ["2004", 1000, 400],
            ["2005", 1170, 460],
            ["2006", 660, 1120],
            ["2007", 1030, 540]
        ];
        */

        if (jsonData === undefined) {
            return [];
        }
        var dateCol = {
            id: "date",
            label: "Date",
            type: "date"
        };
        var cols = [];
        cols.push(dateCol);

        var varieties = {};
        for (var i = 0; i < jsonData.length; i++) {
            var marketData = jsonData[i];
            var varietyName = marketData.variety.name;
            if (varieties[varietyName] === undefined) {
                varieties[varietyName] = varietyName;

                var varietyCol = {
                    id: varietyName,
                    label: varietyName,
                    type: "number"
                };
                cols.push(varietyCol);
            }
        }

        var cells = [];
        for (var c = 0; c < cols.length; c++) {
            cells[c] = null;
        }
        var rows = [];
        var priceDate = null;
        var prevPriceDate = null;
        for (var x = 0; x < jsonData.length; x++) {
            var marketDataObj = jsonData[x];

            // dd-mm-yyyy
            var dateStr = marketDataObj.arrivalDate;
            var dateParts = dateStr.split("-");
            var day = dateParts[0];
            var monthIndex = dateParts[1] - 1;
            var year = dateParts[2];
            priceDate = new Date(year, monthIndex, day);

            var variety = marketDataObj.variety.name;

            if (priceDate !== prevPriceDate) {
                prevPriceDate = priceDate;

                // initialize the Array to be null;
                cells = [];
                for (var j = 0; j < cols.length; j++) {
                    cells[j] = null;
                }
                cells[0] = priceDate;
                rows.push(cells);
            }

            var price = marketDataObj.modalPrice;
            // Get the index of this price based on the variety's position in
            // cols array
            for (var k = 0; k < cols.length; k++) {
                if (cols[k].id === variety) {
                    cells[k] = price;
                    break;
                }
            }
        }

        let data = [];
        data.push(cols);
        data.push(...rows);
        // console.log(data);
        return data;
    }    
}

CommodityPriceChart.propTypes = {
    classes: Proptypes.object.isRequired,
    data: Proptypes.array.isRequired
}

export default withStyles(styles)(CommodityPriceChart);