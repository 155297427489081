import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { AdjacentVarietyMarket, CommodityPrice, VarietyPriceMetadata } from '../CommodityPrice';
import * as Breadcrumbs from "../Nav/Breadcrumbs";
import { NewsContainer } from '../News';
import { AdSlot } from '../utils';
import { BASE_END_POINT } from '../utils/APIConstants';
import { getVarietyDescription, getVarietyKeywords, getVarietyTitle, Helmet, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';
import { viewedVariety } from '../utils/SubscriptionUtils';

class MarketVarietyPrice extends Component {
    
    componentDidUpdate(){
    }

    getBreadcrumbs(variety, market) {
        if (market === null || variety === null) {
            return [];
        }

        const commodity = variety.commodity;
        const district = market.district;
        const state = district.state;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getStatesBreadcrumb(),
            Breadcrumbs.getStateBreadcrumb(state.id, state.name),
            Breadcrumbs.getDistrictBreadcrumb(district.id, state.name, district.name),
            Breadcrumbs.getMarketBreadcrumb(market.id, state.name, district.name, market.name),
            Breadcrumbs.getMarketCommodityBreadcrumb(market.id, commodity.id, commodity.name, state.name, district.name, market.name),
            Breadcrumbs.getVarietyMarketPriceBreadcrumb(variety.id, commodity.name, variety.name, market.id, state.name, district.name, market.name)
        ];

        return breadcrumbs;
    }

    render() {
        return (
            <VarietyPriceMetadata>
                {({ variety, market }) => {
                    const breadcrumbs = this.getBreadcrumbs(variety, market);
                    let resourceUrl = null;
                    let title = null;
                    let loading = true;
                    let commodityName = null, varietyName = null;
                    let marketName = null, districtName = null, stateName = null;

                    if (variety !== null && market !== null) {
                        const commodity = variety.commodity;
                        commodityName = commodity.name;
                        varietyName = variety.name;
                        marketName = market.name;
                        districtName = market.district.name;
                        stateName = market.district.state.name;
                        resourceUrl = `${BASE_END_POINT}/commodity-prices/varietyId/${variety.id}/marketId/${market.id}`;
                        const varietyTitle = variety.name !== commodity.name ? ` [${variety.name}]` : ''
                        const commodityTitle = commodity.name + varietyTitle;
                        title = `${commodityTitle} Prices at ${market.name}`;
                        loading = false;
                        viewedVariety(market.id, variety.id, marketName, districtName, stateName, varietyName, commodityName);
                    }

                    return (
                        <div style={{padding:8}}>
                            <Helmet>
                                <title>{getVarietyTitle(commodityName, varietyName, marketName, districtName, stateName)}</title>
                                <meta name={META_DESCRIPTION} content={getVarietyDescription(commodityName, varietyName, marketName, districtName, stateName)} />
                                <meta name={META_KEYWORDS} content={getVarietyKeywords(commodityName, varietyName, marketName, districtName, stateName)} />
                            </Helmet>
                            <Grid container direction={"column"} spacing={16}>
                                <Grid item>
                                    <Breadcrumbs.default breadcrumbs={breadcrumbs} />
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    <CommodityPrice loading={loading} resourceUrl={resourceUrl} title={title} variety={variety} market={market} />
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    {
                                        variety != null && market != null ?
                                        <AdSlot slot='1221645509' responsive='true' key={variety != null && market != null ? `${variety.id}-${market.id}-1` : `marketVarietyPrice-1`} slotLocation={`MarketVarietyPrice-AfterCommodityPrice`}/>
                                        :
                                        null
                                    }
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    {variety != null && market != null ?
                                        <AdjacentVarietyMarket variety={variety} market={market} />
                                        :
                                        null
                                    }
                                </Grid>
                                {/** 
                                <Grid item md={12} lg={6}>
                                    {
                                        variety != null && market != null ?
                                        <AdSlot slot='6667872287' responsive='true' format='autorelaxed' key={variety != null && market != null ? `${variety.id}-${market.id}-2` : `marketVarietyPrice-2`} slotLocation={`MarketVarietyPrice-AfterAdjacentCommodityMarket`}/>
                                        :
                                        null
                                    }
                                </Grid>
                                */}
                                <Grid item md={12} lg={6}>
                                    {commodityName != null ?
                                        <NewsContainer commodityName={commodityName} />
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    );
                }}
            </VarietyPriceMetadata>
        );
    }
}

export default MarketVarietyPrice;