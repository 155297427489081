import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { BASE_END_POINT } from "../utils/APIConstants";
import { getDistrictBreadcrumb, getStateBreadcrumb, getHomeBreadcrumb, getMarketLink } from "../Nav/Breadcrumbs";
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getTitle, getDescription, getKeywords } from '../utils/SEOUtils';

class District extends Component {
    constructor(props) {
        super(props);
        this.state = {
            district: null
        }
        const { match } = this.props;
        const { districtId } = match.params;
        this.districtId = districtId;
    }

    componentDidMount() {
        const districtResourceUrl = `${BASE_END_POINT}/districts/${this.districtId}`;

        axios.get(districtResourceUrl)
            .then(res => {
                this.setState({ district: res.data });
            })
            .catch(e => {
                console.error(e);
            });
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    getBreadcrumbs(district) {
        if (district === null || district === undefined) {
            return [];
        }
        const state = district.state;
        const breadcrumbs = [
            getHomeBreadcrumb(),
            {
                to: '/states',
                label: 'States'
            },
            getStateBreadcrumb(state.id, state.name),
            getDistrictBreadcrumb(district.id, state.name, district.name)
        ];
        return breadcrumbs;
    }

    render() {
        const { match } = this.props;
        const { districtId, stateName, districtName } = match.params;
        const resourceUrl = `${BASE_END_POINT}/district-id/${districtId}/markets`;
        const breadcrumbs = this.getBreadcrumbs(this.state.district);

        const mapFunction = (market) => {
            const linkProps = {
                to: getMarketLink(market.id, stateName, districtName, market.name),
                label: market.name
            }
            return (
                <NavItem linkProps={linkProps} key={market.id} />
            )
        };

        return (
            <div>
                <Helmet>
                    <title>{getTitle(districtName, stateName)}</title>
                    <meta name="description" content={getDescription(districtName, stateName)} />
                    <meta name="keywords" content={getKeywords(districtName, stateName)} />
                </Helmet>
                <NavCollection breadcrumbs={breadcrumbs} resourceUrl={resourceUrl} mapFunction={mapFunction} />
            </div>
        );
    }
}

export default withRouter(District);