import { Button, Grid, TextField, Typography, Snackbar, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { getConfigWithAuthHeader, withAuthUser } from './components/Auth';
import * as Breadcrumbs from './components/Nav/Breadcrumbs';
import { AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED, BASE_END_POINT } from './components/utils/APIConstants';
import { getPhoneNoWithoutCountryCode, isEmailValid, isphoneNumberValid } from './components/utils/SubscriptionUtils';
import Progress from './components/utils/Progress';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    contactContainer: {
        paddingLeft: 30
    },
    layout: {
        width: 'auto',
        marginLeft: 8,
        marginRight: 8,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    close: {
        padding: theme.spacing.unit / 2,
    }

});

class Contact extends Component {

    constructor(props) {
        super(props);
        const { authUser } = this.props;
        this.repositoryDataLoaded = false;
        this.loadingRepositoryData = false;
        if (authUser === AUTH_STATE_NOT_SIGNED_IN || authUser === AUTH_STATE_NOT_YET_LOADED) {
            this.state = {
                authUser: authUser,
                displayName: '',
                email: '',
                phoneNumber: '',
                loading: true,
                snackbarOpen: false
            };
        } else {
            this.state = {
                authUser: authUser,
                displayName: authUser.displayName === null ? '' : authUser.displayName,
                email: authUser.email === null ? '' : authUser.email,
                phoneNumber: authUser.phoneNumber === null ? '' : getPhoneNoWithoutCountryCode(authUser.phoneNumber),
                snackbarOpen: false
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authUser === AUTH_STATE_NOT_YET_LOADED
            ||
            nextProps.authUser === AUTH_STATE_NOT_SIGNED_IN) {
            return null;
        }
        if (nextProps.authUser !== prevState.authUser) {
            let phoneNumberFromFirebase = nextProps.authUser.phoneNumber;
            phoneNumberFromFirebase = getPhoneNoWithoutCountryCode(phoneNumberFromFirebase);
            return {
                authUser: nextProps.authUser,
                displayName: prevState.displayName === '' ?
                    (nextProps.authUser.displayName === null ? '' : nextProps.authUser.displayName) : prevState.phoneNumber,
                email: prevState.email === '' ?
                    (nextProps.authUser.email === null ? '' : nextProps.authUser.email)
                    : prevState.email,
                phoneNumber: prevState.phoneNumber === '' ?
                    (phoneNumberFromFirebase === null ? '' : phoneNumberFromFirebase)
                    : prevState.phoneNumber
            };
        }
        else {
            return null;
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
        this.loadRepositoryData();
    }

    componentDidUpdate() {
        this.loadRepositoryData();
    }

    async loadRepositoryData() {
        if ((this.state.authUser !== AUTH_STATE_NOT_SIGNED_IN
            && this.state.authUser !== AUTH_STATE_NOT_YET_LOADED)
            && this.repositoryDataLoaded === false) {
            if (this.loadingRepositoryData === false) {
                const config = await getConfigWithAuthHeader(this.state.authUser);
                const url = `${BASE_END_POINT}/users/currentUserWithAttributes`;
                this.loadingRepositoryData = true;
                axios.get(url, config).then((res) => {
                    this.setStateFromResponse(res);
                    this.repositoryDataLoaded = true;
                    this.loadingRepositoryData = false;
                }).catch((err) => {
                    this.repositoryDataLoaded = true;
                    this.loadingRepositoryData = false;
                    this.setState({
                        loading: false
                    });
                    console.log(err);
                });
            }
        }

        // Since we can reach here when user's have not logged in, remove the loading state so that the form can be seen.
        //this.setState({
        // loading: false
        // });
    }

    setStateFromResponse = (res) => {
        const userData = res.data;
        // Since the other fields are read only set only phone number and allow users to update phone number.
        this.setState({
            displayName: userData.firstName === null ? '' : userData.firstName,
            phoneNumber: userData.phoneNumber === null ? '' : userData.phoneNumber,
            email: userData.email === null ? '' : userData.email,
            loading: false
        })
    }

    changeTextArea = () => {
        // console.log(this.state);
    }

    submitFormWithAuth = async (values, formikBag) => {
        this.setState({
            loading: true
        })
        const formData = {
            displayName: values.displayName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            message: values.message
        };
        console.log(formData);
        const url = `${BASE_END_POINT}/message/contactUs`;
        axios.post(url, formData).then((res) => {
            this.afterFormSubmission(formikBag);
        }).catch((err) => {
            console.log(err);
        });;
    }

    afterFormSubmission(formikBag) {
        this.setState({
            loading: false
        });
        this.openSnackbar();
        this.redirect();
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            snackbarOpen: false
        });
    }

    openSnackbar = () => {
        this.setState({
            snackbarOpen: true
        });
    }

    redirect = () => {
        const { history } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        setTimeout(() => {
            history.push(Breadcrumbs.getHomeLink());
        }, 2000);
    }

    handleSubmit = (values, formikBag) => {
        this.submitFormWithAuth(values, formikBag);
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            snackbarOpen: false
        });
    }

    openSnackbar = () => {
        this.setState({
            snackbarOpen: true
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container direction="column" spacing={16} className={classes.contactContainer}>
                <Grid item>
                    <Typography variant="h4">Contact Us</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">email us at support@anvayin.com or send us a message using the form below. We will be glad to respond to your questions or comments.</Typography>
                </Grid>
                {this.state.loading ?
                    <Progress />
                    :
                    <Grid item>
                        <Formik
                            initialValues={{
                                displayName: this.state.displayName,
                                email: this.state.email,
                                phoneNumber: this.state.phoneNumber,
                                message: ''
                            }}
                            // Explore 'Yup' for advanced validaton.
                            validate={values => {
                                let errors = {};
                                if (!values.displayName) {
                                    errors.displayName = 'Required';
                                }
                                if (!values.phoneNumber) {
                                    errors.phoneNumber = 'Required';
                                }
                                else if (!isphoneNumberValid(values.phoneNumber)) {
                                    errors.phoneNumber = 'Invalid Phone Number';
                                }
                                if (!values.email) {
                                    errors.email = 'Required';
                                }
                                else if (!isEmailValid(values.email)) {
                                    errors.email = 'Invalid eMail';
                                }
                                const MAX_LENGTH = 2000;
                                if (!values.message) {
                                    errors.message = 'Required';
                                } else if (values.message.length > MAX_LENGTH) {
                                    errors.message = `Maximum length of the message can be ${MAX_LENGTH}. You have entered ${values.message.length} characters.`;
                                }
                                return errors;
                            }}
                            onSubmit={(values, formikBag) => {
                                this.handleSubmit(values, formikBag);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={24} direction={"column"}>
                                            <Grid item xs={12}>
                                                {errors.displayName
                                                    && touched.displayName
                                                    && <Typography color={'error'}>{errors.displayName}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.displayName
                                                        && touched.displayName}
                                                    id="displayName"
                                                    name="displayName"
                                                    label="Name"
                                                    type="text"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.displayName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {errors.email
                                                    && touched.email
                                                    && <Typography color={'error'}>{errors.email}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.email
                                                        && touched.email}
                                                    id="email"
                                                    name="email"
                                                    label="eMail"
                                                    type="email"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {errors.phoneNumber
                                                    && touched.phoneNumber
                                                    && <Typography color={'error'}>{errors.phoneNumber}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.phoneNumber
                                                        && touched.phoneNumber}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    label="Phone Number"
                                                    type="text"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />

                                            </Grid>

                                            <Grid item xs={12}>
                                                {errors.message
                                                    && touched.message
                                                    && <Typography color={'error'}>{errors.message}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.message
                                                        && touched.message}
                                                    id="message"
                                                    name="message"
                                                    label="Message"
                                                    type="text"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    multiline={true}
                                                    rows={5}
                                                    rowsMax={10}
                                                />

                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth disabled={isSubmitting}>{'Send'}</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                        </Formik>
                    </Grid>
                }

                <div style={{ width: 200 }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Message Sent</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleSnackbarClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>


            </Grid>

        );
    }
}

export default withAuthUser(withStyles(styles, { withTheme: true })(Contact));