import React from 'react';
import { BASE_END_POINT } from '../utils/APIConstants';
import axios from 'axios';
import { withRouter } from 'react-router';


class CommodityPriceMetadata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            market: null,
            commodity: null
        }
        this.commodityCancelTokenSource = null;
        this.marketCancelTokenSource = null;
        this.marketId = null;
        this.commodityId = null;
    }

    componentDidMount() {
        this.fetchMetadata();
    }

    fetchMetadata() {
        const { match } = this.props;
        const { params } = match;
        const { commodityId, commodityName, marketId, stateName, districtName, marketName } = params;
        if (this.marketId === marketId && this.commodityId === commodityId) {
            return;
        }

        this.commodityId = commodityId;
        this.marketId = marketId;

        let commodityResourceUrl = null;
        let marketResourceUrl = null;

        if (commodityId !== undefined) {
            commodityResourceUrl = `${BASE_END_POINT}/commodities/${commodityId}`;
        } else if (commodityName !== undefined) {
            commodityResourceUrl = `${BASE_END_POINT}/commodityByName/${commodityName}`;
        }

        if (marketId !== undefined) {
            marketResourceUrl = `${BASE_END_POINT}/markets/${marketId}`;
        } else if (marketName !== undefined) {
            if (stateName !== undefined && districtName !== undefined) {
                marketResourceUrl = `${BASE_END_POINT}/marketByName/${stateName}/${districtName}/${marketName}`;
            } else {
                marketResourceUrl = `${BASE_END_POINT}/marketByName/${marketName}`;
            }
        }

        if (commodityResourceUrl !== null && marketResourceUrl != null) {
            let commodityCancelToken = axios.CancelToken;
            this.commodityCancelTokenSource = commodityCancelToken.source();

            let marketCancelToken = axios.CancelToken;
            this.marketCancelTokenSource = marketCancelToken.source();

            const getCommodity = () => axios.get(commodityResourceUrl, { cancelToken: this.commodityCancelTokenSource.token });
            const getMarket = () => axios.get(marketResourceUrl, { cancelToken: this.marketCancelTokenSource.token });

            axios.all([getCommodity(), getMarket()])
                .then(axios.spread((commodityRes, marketRes) => {
                    this.commodityCancelTokenSource = null;
                    this.marketCancelTokenSource = null;
                    this.setState({
                        commodity: commodityRes.data,
                        market: marketRes.data
                    });
                })).catch(e => {
                    if (axios.isCancel(e)) {
                        return
                    }
                });
        }
    }

    componentDidUpdate() {
        this.fetchMetadata();
    }

    componentWillUnmount() {
        if (this.commodityCancelTokenSource !== null) {
            this.commodityCancelTokenSource.cancel();
        }

        if (this.marketCancelTokenSource !== null) {
            this.marketCancelTokenSource.cancel();
        }
    }


    render() {
        const { children } = this.props;
        return children({ market: this.state.market, commodity: this.state.commodity });
    }
}

export default withRouter(CommodityPriceMetadata);