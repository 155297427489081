import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Person as PersonIcon, ImportExport as ImportExportIcon, LocalFlorist as LocalFloristIcon, } from '@material-ui/icons'

function AboutUs(props) {
    return (
        <Grid container direction={"column"} style={{ padding: 16 }}>
            <Grid item>
                <Typography variant={"h5"}>About Us</Typography>
                <br />
                <br />
            </Grid>
            <Grid item>
                <Typography variant={"body2"} component={"div"}>
                    <Typography variant={"h6"}> Who we are </Typography>
                    <br /> Anvayin Commodities is an online agriculture portal to search for live commodity
                    prices, cold storage areas, news, weather forecast from various markets across India.
                    <br />
                    <br />
                    <Typography variant={"h6"}>What we provide</Typography>
                    <br /> Our vision is to provide information and services to the farming community and
                    private sector through the use of information and communication technologies. Agri Portal
                    is an endeavour in this direction to create one stop shop for meeting all informational
                    needs relating to Agriculture in India.
                            <br />
                    <br />
                    <Typography variant={"h6"}>Features </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon><LocalFloristIcon /></ListItemIcon>
                            <ListItemText>Check prices of commodities in various markets.</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ImportExportIcon /></ListItemIcon>
                            <ListItemText>Check daily, weekly, monthly trends.</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><PersonIcon /></ListItemIcon>
                            <ListItemText>Register and access for preferred markets.</ListItemText>
                        </ListItem>
                    </List>
                </Typography>
            </Grid>
        </Grid>

    );
}

export default AboutUs;