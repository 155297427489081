import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { Link } from 'react-router-dom';

const style = () => {
    return ({
        breadcrumbContainer: {
        },
        navLink: {
            textDecoration: 'none'
        }
    });
};

function Breadcrumbs(props) {
    const { breadcrumbs, classes } = props;
    return (
        <Grid container direction={'row'} spacing={8} className={classes.breadcrumbContainer}>
            {
                breadcrumbs.map((breadcrumb, index, breadcrumbs) => {
                    return (
                        <React.Fragment key={index}>
                            <Grid item key={index}>
                                <Button component={Link} to={breadcrumb.to} color={'secondary'}>
                                    {breadcrumb.label}
                                </Button>
                            </Grid>
                            {breadcrumbs.length > index + 1 ?
                                <Grid item style={{ alignSelf: 'center' }} key={`${index}Icon`}>
                                    <ChevronRightIcon alignmentBaseline={'middle'} style={{ fontSize: 14 }} />
                                </Grid> : null
                            }
                        </React.Fragment>
                    );
                })
            }
        </Grid>
    );
}

export const getStatesBreadcrumb = () => {
    return {
        to: '/states',
        label: 'States'
    };
};

export const getHomeBreadcrumb = () => {
    return {
        to: getHomeLink(),
        label: 'Home'
    };
};

export const getHomeLink = () => {
    return '/';
}

export const getSignInLink = () => {
    return '/signIn';
}

export const getProfileLink = () => {
    return '/profile';
}

export const getSubscriptionLink = () => {
    return '/subscription';
}

export const getCommoditiesBreadcrumb = () => {
    return {
        to: `/commodities`,
        label: 'Commodities'
    };
};

export const getCategoriesBreadcrumb = () => {
    return {
        to: `/categories`,
        label: 'Categories'
    };
};

export const getCommodityStatesLink = (categoryId, categoryName, commodityId, commodityName) => {
    return `/commodity-market/${categoryId}/${categoryName}/${commodityId}/${commodityName}/states`;
};

export const getCategoryCommoditiesLink = (categoryId, categoryName) => {
    return `/category-commodity/${categoryId}/${categoryName}/commodities`;
}

export const getCategoryCommoditiesBreadcrumb = (categoryId, categoryName) => {
    return {
        to: getCategoryCommoditiesLink(categoryId, categoryName),
        label: categoryName
    }
}

export const getCommodityStateLink = (categoryId, categoryName, commodityId, commodityName, stateId, stateName) => {
    return `/commodity-market/${categoryId}/${categoryName}/${commodityId}/${commodityName}/${stateId}/${stateName}/districts`;
};

export const getVarietyStatesLink = (varietyId, commodityName, varietyName) => {
    return `/variety-market/${varietyId}/${commodityName}/${varietyName}/states`;
}

export const getVarietyStateLink = (varietyId, commodityName, varietyName, stateId, stateName) => {
    return `/variety-market/${varietyId}/${commodityName}/${varietyName}/${stateId}/${stateName}/districts`;
};

export const getCommodityStatesBreadcrumb = (categoryId, categoryName, commodityId, commodityName) => {
    return {
        to: getCommodityStatesLink(categoryId, categoryName, commodityId, commodityName),
        label: commodityName
    }
};

export const getVarietyStatesBreadcrumb = (varietyId, commodityName, varietyName) => {
    return {
        to: getVarietyStatesLink(varietyId, commodityName, varietyName),
        label: `${varietyName} (${commodityName})`
    }
}

export const getVarietyStateBreadcrumb = (varietyId, commodityName, varietyName, stateId, stateName) => {
    return {
        to: getVarietyStateLink(varietyId, commodityName, varietyName, stateId, stateName),
        label: stateName
    }
}

export const getCommodityStateBreadcrumb = (categoryId, categoryName, commodityId, commodityName, stateId, stateName) => {
    return {
        to: getCommodityStateLink(categoryId, categoryName, commodityId, commodityName, stateId, stateName),
        label: stateName
    }
};

export function getDistrictLink(districtId, stateName, districtName) {
    return `/district-id/${districtId}/state/${stateName}/district/${districtName}`;
}

export const getCommodityMarketLink = (categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName, marketId, marketName) => {
    return `/commodity-market/${categoryId}/${categoryName}/${commodityId}/${commodityName}/${stateId}/${stateName}/${districtId}/${districtName}/${marketId}/${encodeURIComponent(marketName)}`;
}

export const getCommodityMarketBreadcrumb = (categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName, marketId, marketName) => {
    return {
        to: getCommodityMarketLink(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName, marketId, marketName),
        label: decodeURIComponent(marketName)
    }
};

export const getCommodityDistrictLink = (categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName) => {
    return `/commodity-market/${categoryId}/${categoryName}/${commodityId}/${commodityName}/${stateId}/${stateName}/${districtId}/${districtName}`;
};

export const getVarietyDistrictLink = (varietyId, commodityName, varietyName, districtId, stateName, districtName) => {
    return `/variety-market/${varietyId}/${commodityName}/${varietyName}/${districtId}/${stateName}/${districtName}/markets`;
}

export function getDistrictBreadcrumb(districtId, stateName, districtName) {
    return {
        to: getDistrictLink(districtId, stateName, districtName),
        label: districtName
    };
}

export const getCommodityDistrictBreadcrumb = (categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName) => {
    return {
        to: getCommodityDistrictLink(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName),
        label: districtName
    }
};

export const getVarietyDistrictBreadcrumb = (varietyId, commodityName, varietyName, districtId, stateName, districtName) => {
    return {
        to: getVarietyDistrictLink(varietyId, commodityName, varietyName, districtId, districtName, stateName),
        label: districtName
    }
}

export function getStateLink(stateId, stateName) {
    return `/state-id/${stateId}/state/${stateName}`;
}

export function getStateBreadcrumb(stateId, stateName) {
    return {
        to: `/state-id/${stateId}/state/${stateName}`,
        label: stateName
    };
}

export const getMarketLink = (marketId, stateName, districtName, marketName) => {
    return `/market-id/${marketId}/state/${stateName}/district/${districtName}/market/${encodeURIComponent(marketName)}`;
};

export const getMarketBreadcrumb = (marketId, stateName, districtName, marketName) => {
    return {
        to: getMarketLink(marketId, stateName, districtName, marketName),
        label: marketName
    }
};

export const getMarketCommodityLink = (marketId, commodityId, commodityName, stateName, districtName, marketName) => {
    return `/market-commodity/${marketId}/${commodityId}/${commodityName}/${stateName}/${districtName}/${marketName}`;
}

export const getMarketCommodityBreadcrumb = (marketId, commodityId, commodityName, stateName, districtName, marketName) => {
    return {
        to: getMarketCommodityLink(marketId, commodityId, commodityName, stateName, districtName, marketName),
        label: commodityName
    };
}

export const getCommodityPriceLink = (commodityId, commodityName, marketId, stateName, districtName, marketName) => {
    return `/price/${commodityId}/${commodityName}/${marketId}/${stateName}/${districtName}/${marketName}`;
};

export const getCommodityMarketPriceLink = (commodityId, commodityName, marketId, stateName, districtName, marketName) => {
    return `/commodity-market-price/${commodityId}/${commodityName}/${marketId}/${stateName}/${districtName}/${marketName}`;
};

export const getVarietyMarketPriceLink = (varietyId, commodityName, varietyName, marketId, stateName, districtName, marketName) => {
    return `/variety-market-price/${varietyId}/${marketId}/${commodityName}/${varietyName}/${stateName}/${districtName}/${marketName}`;
};

export const getMarketVarietyPriceLink = (varietyId, commodityName, varietyName, marketId, stateName, districtName, marketName) => {
    return `/market-variety-price/${varietyId}/${marketId}/${commodityName}/${varietyName}/${stateName}/${districtName}/${marketName}`;
};

export const getCommodityPriceBreadcrumb = (commodityId, commodityName, marketId, stateName, districtName, marketName) => {
    return {
        to: getCommodityPriceLink(commodityId, commodityName, marketId, stateName, districtName, marketName),
        label: commodityName
    };
};

export const getCommodityMarketPriceBreadcrumb = (commodityId, commodityName, marketId, stateName, districtName, marketName) => {
    return {
        to: getCommodityMarketPriceLink(commodityId, commodityName, marketId, stateName, districtName, marketName),
        label: marketName
    };
};

export const getVarietyMarketPriceBreadcrumb = (varietyId, commodityName, varietyName, marketId, stateName, districtName, marketName) => {
    return {
        to: getVarietyMarketPriceLink(varietyId, commodityName, varietyName, marketId, stateName, districtName, marketName),
        label: varietyName
    };
};

export const getSellAdLink = (sellAdId) => {
    return `/sell-ad/${sellAdId}`;
}

export const getSellAdEditLink = (sellAdId) => {
    return `/sell-ad/${sellAdId}/edit`;
}

export default withStyles(style)(Breadcrumbs);