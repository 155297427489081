import { Button, Grid, IconButton, Snackbar, TextField, Typography } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import Downshift from "downshift";
import { Formik } from 'formik';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import Axios from '../../axios';
import { getConfigWithAuthHeader, withAuthUser } from '../Auth';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED, BASE_END_POINT } from '../utils/APIConstants';
import Progress from '../utils/Progress';

const ENDPOINT = BASE_END_POINT + '/_search';
const COMMODITY_SEARCH_ENDPOINT = `${ENDPOINT}/commodities`;
const MARKET_SEARCH_ENDPOINT = `${ENDPOINT}/markets`;
const VARIETY_SEARCH_ENDPOINT = `${ENDPOINT}/varieties/commodity-id/`;
const TYPE_VARIETY = 'Variety';
const TYPE_COMMODITY = 'Commodity';
const TYPE_MARKET = 'Market';

const styles = (theme) => {
    return (
        {
            layout: {
                width: 'auto',
                marginLeft: theme.spacing.unit * 2,
                marginRight: theme.spacing.unit * 2,
                [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
                    width: 600,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            },
            close: {
                padding: theme.spacing.unit / 2,
            },
            menuWidth: {
                [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
                    width: 600,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
                minWidth: 320
            },
        }
    );
}

class SellAdUpdate extends Component {
    constructor(props) {
        super(props);
        const { authUser } = this.props;
        this.commodityInputRef = React.createRef();
        this.marketInputRef = React.createRef();
        this.varietyInputRef = React.createRef();
        this.selectedCommodity = null;
        this.state = {
            selectedCommodity: null,
            selectedMarket: null,
            selectedVariety: null,
            authUser: authUser,
            isNew: !this.props.match.params || !this.props.match.params.sellAdId,
            loading: true,
            sellAd: null
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.setState({
                loading: false
            })
        } else {
            const { match } = this.props;
            const { sellAdId } = match.params;
            const resourceUrl = `${BASE_END_POINT}/sell-ads/${sellAdId}`;
            axios.get(resourceUrl).then(res => {
                this.setStateFromResponse(res);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    setStateFromResponse = (res) => {
        const sellAd = res.data;
        this.setState({
            sellAd,
            selectedCommodity: sellAd.variety.commodity,
            selectedVariety: sellAd.variety,
            selectedMarket: sellAd.market,
            loading: false
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authUser === AUTH_STATE_NOT_YET_LOADED
            ||
            nextProps.authUser === AUTH_STATE_NOT_SIGNED_IN) {
            return null;
        }
        if (nextProps.authUser !== prevState.authUser) {
            return {
                authUser: nextProps.authUser,
            };
        }
        else {
            return null;
        }
    }

    onCommoditySelect = (selectedItem) => {
        this.setState(
            {
                selectedCommodity: selectedItem,
            });
    }
    onMarketSelect = (selectedItem) => {
        this.setState({
            selectedMarket: selectedItem
        });
    }
    onVarietySelect = (selectedItem) => {
        this.setState({
            selectedVariety: selectedItem
        });
    }

    submitFormWithAuth = async (values, formikBag) => {
        if (this.state.authUser === AUTH_STATE_NOT_SIGNED_IN ||
            this.state.authUser === AUTH_STATE_NOT_YET_LOADED) {
            return;
        }
        const config = await getConfigWithAuthHeader(this.state.authUser);
        this.setState({
            loading: true
        })
        const formData = {
            id: this.state.isNew ? null : this.state.sellAd.id,
            variety: this.state.selectedVariety,
            market: this.state.selectedMarket,
            quantity: values.quantity,
            price: values.price,
            description: values.description
        }
        const url = `${BASE_END_POINT}/sell-ads/current-user`;
        const httpMethod = this.state.isNew ? 'post' : 'put';

        axios({ method: httpMethod, url: url, data: formData, ...config }).then((res) => {
            this.setStateFromResponse(res);
            formikBag.setSubmitting(false);
            this.openSnackbar();
            this.redirect();
        }).catch(err => console.log(err));
    }

    redirect = () => {
        const { history } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        if (from.pathname === '/') {
            setTimeout(() => { history.push(Breadcrumbs.getHomeLink()); }, 3000);
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            snackbarOpen: false
        });
    }

    openSnackbar = () => {
        this.setState({
            snackbarOpen: true
        });
    }

    handleSubmit = (values, formikBag) => {
        this.submitFormWithAuth(values, formikBag);
    }
    render() {
        const { classes } = this.props;
        return (
            <Grid container direction={"column"} spacing={24} className={classes.layout} justify={"center"}>
                <Grid item style={{ alignSelf: "center" }}>
                    <Typography variant={"h5"} gutterBottom>{this.state.isNew ? `List Your Commodity` : `Edit Your Listing`}</Typography>
                </Grid>
                {this.state.loading ?
                    <Progress />
                    :
                    <React.Fragment>
                        <Grid item>
                            <Formik
                                initialValues={{
                                    commodity: this.state.isNew ? '' : this.state.sellAd.variety.commodity.name,
                                    variety: this.state.isNew ? '' : this.state.sellAd.variety.name,
                                    market: this.state.isNew ? '' : this.state.sellAd.market.name,
                                    quantity: this.state.isNew ? '' : this.state.sellAd.quantity,
                                    price: this.state.isNew ? '' : this.state.sellAd.price,
                                    description: this.state.isNew ? '' : this.state.sellAd.description
                                }}
                                validate={values => {
                                    let errors = {};
                                    return errors;
                                }}
                                onSubmit={(values, formikBag) => {
                                    this.handleSubmit(values, formikBag);
                                }}
                            >
                                {
                                    ({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={24} direction={"column"}>
                                                    <Grid item>
                                                        <Downshift itemToString={i => (i == null ? '' : i.name)} defaultHighlightedIndex={0}
                                                            onSelect={
                                                                (selectedItem) => {
                                                                    setFieldValue('commodity', selectedItem.name);
                                                                    setFieldValue('variety', '');
                                                                    this.onCommoditySelect(selectedItem);
                                                                }
                                                            }
                                                            inputValue={values.commodity}
                                                        >
                                                            {({ getInputProps,
                                                                getItemProps,
                                                                getMenuProps,
                                                                highlightedIndex,
                                                                inputValue,
                                                                isOpen,
                                                                selectedItem,
                                                            }) => {
                                                                return (
                                                                    <div>
                                                                        <TextField id="commodity"
                                                                            name="commodity"
                                                                            label="Commodity"
                                                                            required
                                                                            type="text"
                                                                            fullWidth
                                                                            InputProps={getInputProps({
                                                                                placeholder: 'e.g. Turmeric',
                                                                                onChange: handleChange,
                                                                                onBlur: handleBlur
                                                                            })}
                                                                            inputRef={node => {
                                                                                this.commodityInputRef = node;
                                                                            }}
                                                                            value={values.commodity}
                                                                        />
                                                                        <Popper open={isOpen} anchorEl={this.commodityInputRef}>
                                                                            <div {...getMenuProps({}, { suppressRefError: true })} className={classes.menuWidth}>
                                                                                {isOpen ? (
                                                                                    <Axios url={COMMODITY_SEARCH_ENDPOINT} params={{ query: deburr(inputValue) }}
                                                                                        skipRequest={{ skip: deburr(inputValue).length < 3, defaultReturnValue: [] }}
                                                                                        withDebounce={true}>
                                                                                        {({ data = null }) => {
                                                                                            const commodities = data;
                                                                                            return <Paper className={classes.paper} square>
                                                                                                {
                                                                                                    commodities !== null ? commodities.map((dataItem, index) => {
                                                                                                        const item = dataItem;
                                                                                                        item.type = TYPE_COMMODITY;
                                                                                                        return renderMenuItem({
                                                                                                            id: dataItem.id,
                                                                                                            item,
                                                                                                            index,
                                                                                                            itemProps: getItemProps({ item: item }),
                                                                                                            highlightedIndex,
                                                                                                            selectedItem,
                                                                                                        })
                                                                                                    }, this) : null
                                                                                                }
                                                                                            </Paper>
                                                                                        }}
                                                                                    </Axios>
                                                                                ) : null}
                                                                            </div>
                                                                        </Popper>

                                                                    </div>
                                                                );
                                                            }}
                                                        </Downshift>
                                                    </Grid>
                                                    {
                                                        this.state.selectedCommodity != null ?
                                                            <Grid item>
                                                                <Downshift itemToString={i => (i == null ? '' : i.name)} defaultHighlightedIndex={0}
                                                                    onSelect={
                                                                        (selectedItem) => {
                                                                            setFieldValue('variety', selectedItem.name)
                                                                            this.onVarietySelect(selectedItem);
                                                                        }
                                                                    }
                                                                    inputValue={values.variety}
                                                                >
                                                                    {({ getInputProps,
                                                                        getItemProps,
                                                                        getMenuProps,
                                                                        highlightedIndex,
                                                                        inputValue,
                                                                        isOpen,
                                                                        selectedItem,
                                                                        openMenu
                                                                    }) => {
                                                                        const VARIETY_SEARCH_ENDPOINT_FOR_COMMODITY = `${VARIETY_SEARCH_ENDPOINT}/${this.state.selectedCommodity.id}`;
                                                                        return (
                                                                            <div>
                                                                                <TextField id="variety"
                                                                                    name="variety"
                                                                                    label="Variety"
                                                                                    required
                                                                                    type="text"
                                                                                    fullWidth
                                                                                    InputProps={getInputProps({
                                                                                        placeholder: 'e.g. Bulb or Other',
                                                                                        onFocus: openMenu,
                                                                                        onChange: handleChange,
                                                                                        onBlur: handleBlur
                                                                                    })}
                                                                                    inputRef={node => {
                                                                                        this.varietyInputRef = node;
                                                                                    }}
                                                                                    value={values.variety}
                                                                                />
                                                                                <Popper open={isOpen} anchorEl={this.varietyInputRef}>
                                                                                    <div {...getMenuProps({}, { suppressRefError: true })} className={classes.menuWidth}>
                                                                                        {isOpen ? (
                                                                                            <Axios url={VARIETY_SEARCH_ENDPOINT_FOR_COMMODITY} params={{ query: deburr(inputValue) }}
                                                                                                // skipRequest={{ skip: deburr(inputValue).length < 3, defaultReturnValue: [] }}
                                                                                                withDebounce={true}>
                                                                                                {({ data = null }) => {
                                                                                                    const varieties = data;
                                                                                                    return <Paper className={classes.paper} square>
                                                                                                        {
                                                                                                            varieties !== null ? varieties.map((dataItem, index) => {
                                                                                                                const item = dataItem;
                                                                                                                item.type = TYPE_VARIETY;
                                                                                                                return renderMenuItem({
                                                                                                                    id: dataItem.id,
                                                                                                                    item,
                                                                                                                    index,
                                                                                                                    itemProps: getItemProps({ item: item }),
                                                                                                                    highlightedIndex,
                                                                                                                    selectedItem,
                                                                                                                })
                                                                                                            }, this) : null
                                                                                                        }
                                                                                                    </Paper>
                                                                                                }}
                                                                                            </Axios>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </Popper>
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Downshift>
                                                            </Grid>
                                                            : null
                                                    }
                                                    <Grid item>
                                                        <Downshift itemToString={i => (i == null ? '' : i.name)} defaultHighlightedIndex={0}
                                                            onSelect={
                                                                (selectedItem) => {
                                                                    setFieldValue('market', selectedItem.name);
                                                                    this.onMarketSelect(selectedItem);
                                                                }
                                                            }
                                                            inputValue={values.market}
                                                        >
                                                            {({ getInputProps,
                                                                getItemProps,
                                                                getMenuProps,
                                                                highlightedIndex,
                                                                inputValue,
                                                                isOpen,
                                                                selectedItem,
                                                            }) => {
                                                                return (
                                                                    <div>
                                                                        <TextField id="market"
                                                                            name="market"
                                                                            label="Nearest Market"
                                                                            required
                                                                            type="text"
                                                                            fullWidth
                                                                            InputProps={getInputProps({
                                                                                placeholder: 'e.g. Madathukulam',
                                                                                onChange: handleChange,
                                                                                onBlur: handleBlur
                                                                            })}
                                                                            inputRef={node => {
                                                                                this.marketInputRef = node;
                                                                            }}
                                                                            value={values.market}
                                                                        />
                                                                        <Popper open={isOpen} anchorEl={this.marketInputRef}>
                                                                            <div {...getMenuProps({}, { suppressRefError: true })} className={classes.menuWidth}>
                                                                                {isOpen ? (
                                                                                    <Axios url={MARKET_SEARCH_ENDPOINT} params={{ query: deburr(inputValue) }}
                                                                                        skipRequest={{ skip: deburr(inputValue).length < 3, defaultReturnValue: [] }}
                                                                                        withDebounce={true}>
                                                                                        {({ data = null }) => {
                                                                                            const markets = data;
                                                                                            return <Paper className={classes.paper} square>
                                                                                                {
                                                                                                    markets !== null ? markets.map((dataItem, index) => {
                                                                                                        const item = dataItem;
                                                                                                        item.type = TYPE_MARKET;
                                                                                                        return renderMenuItem({
                                                                                                            id: dataItem.id,
                                                                                                            item,
                                                                                                            index,
                                                                                                            itemProps: getItemProps({ item: item }),
                                                                                                            highlightedIndex,
                                                                                                            selectedItem,
                                                                                                        })
                                                                                                    }, this) : null
                                                                                                }
                                                                                            </Paper>
                                                                                        }}
                                                                                    </Axios>
                                                                                ) : null}
                                                                            </div>
                                                                        </Popper>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Downshift>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField id="quantity"
                                                            name="quantity"
                                                            label="Quantity"
                                                            required
                                                            type="number"
                                                            fullWidth
                                                            value={values.quantity}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField id="price"
                                                            name="price"
                                                            label="Price"
                                                            required
                                                            type="number"
                                                            fullWidth
                                                            value={values.price}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {errors.description
                                                            && touched.description
                                                            && <Typography color={'error'}>{errors.description}</Typography>}
                                                        <TextField
                                                            required
                                                            error={errors.description
                                                                && touched.description}
                                                            id="description"
                                                            name="description"
                                                            label="Description"
                                                            type="text"
                                                            fullWidth
                                                            autoComplete={"off"}
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            multiline={true}
                                                            rows={5}
                                                            rowsMax={10}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth disabled={isSubmitting}>{'Save'}</Button>
                                                    </Grid>

                                                </Grid>

                                            </form>
                                        )
                                }

                            </Formik>
                        </Grid>
                        <Grid item>
                            <div style={{ width: 200 }}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={this.state.snackbarOpen}
                                    autoHideDuration={3000}
                                    onClose={this.handleSnackbarClose}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={<span id="message-id"> <span >Commodity listing saved.</span> <br />  <span>It will be published soon.</span><br /> <br /> </span>}
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            className={classes.close}
                                            onClick={this.handleSnackbarClose}
                                        >
                                            <CloseIcon />
                                        </IconButton>,
                                    ]}
                                />
                            </div>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        );
    }
}

function renderMenuItem({ id, item, index, itemProps, highlightedIndex, selectedItem, type }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === item;

    let displayString;
    if (item.type === TYPE_MARKET) {
        displayString = `${item.name} - ${item.district.name}, ${item.district.state.name}`;
    } else if (item.type === TYPE_COMMODITY) {
        displayString = `${item.name}`;
    } else if (item.type === TYPE_VARIETY) {
        displayString = `${item.name}`;
    }

    return (
        <MenuItem
            {...itemProps}
            key={id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {displayString}
        </MenuItem>
    );
}

renderMenuItem.propTypes = {
    id: PropTypes.number,
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    selectedItem: PropTypes.string,
    item: PropTypes.string.isRequired,
};

export default withRouter(withAuthUser(withStyles(styles)(SellAdUpdate)));