import React from 'react';
import { Grid, Card, Typography, CardHeader, CardContent, CardActions, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { StarBorder as StarIcon } from '@material-ui/icons';
import { withAuthUser, getConfigWithAuthHeader } from '../Auth';
import axios from 'axios';
import { BASE_END_POINT, AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED } from '../utils/APIConstants';
import { Progress } from '../utils';
import { withRouter } from "react-router";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { isphoneNumberValid, isEmailValid } from '../utils/SubscriptionUtils';
import { Helmet, getSubscriptionTitle } from '../utils/SEOUtils';
import ReactGA from "react-ga";

const styles = theme => ({
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 2}px`,
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing.unit * 2,
    },
    cardActions: {
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing.unit * 2,
        },
    }
});

const tiers = [
    {
        durationInMonths: 1,
        // title: '1 Month',
        // price: '₹150',
        description: [
            'Unlimited Markets',
            'Unlimited Commodities',
            'Listing of Markets by Commodities',
            'Listing of Commodities by Markets',
            'Adjacent Markets'
        ],
        buttonText: 'Try Now',
        buttonVariant: 'outlined'
    },
    {
        durationInMonths: 3,
        // title: '3 Months',
        subheader: 'Most popular',
        // price: '₹450',
        description: [
            'Unlimited Markets',
            'Unlimited Commodities',
            'Listing of Markets by Commodities',
            'Listing of Commodities by Markets',
            'Adjacent Markets'
        ],
        buttonText: 'Get Started',
        buttonVariant: 'contained'
    },
    {
        durationInMonths: 6,
        // title: '6 Months',
        // price: '₹900',
        description: [
            'Unlimited Markets',
            'Unlimited Commodities',
            'Listing of Markets by Commodities',
            'Listing of Commodities by Markets',
            'Adjacent Markets'
        ],
        buttonText: 'Subscribe',
        buttonVariant: 'outlined'
    }
];

class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            subscriptionStatus: 'null',
        }
        this.email = '';
        this.phoneNumber = '';
        this.repositoryDataLoaded = false;
        this.loadingRepositoryData = false;
        this.submitting = false;
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        const { authUser } = this.props;
        if (authUser !== AUTH_STATE_NOT_SIGNED_IN || authUser !== AUTH_STATE_NOT_YET_LOADED) {
            this.getUserAttributes();
        }
    }

    getUserAttributes = async () => {
        if (this.loadingRepositoryData === false) {
            this.loadingRepositoryData = true;
            const { authUser } = this.props;
            const config = await getConfigWithAuthHeader(authUser);
            const url = `${BASE_END_POINT}/users/currentUserWithAttributes`;
            axios.get(url, config).then((res) => {
                const userData = res.data;
                // Since the other fields are read only set only phone number and allow users to update phone number.
                this.phoneNumber = userData.phoneNumber === null ? '' : userData.phoneNumber;
                this.email = userData.email === null ? '' : userData.email;
                // Move this to State so that the subscription message can be adjusted based on the subscription status.
                // implement repositoryDataLoaded, loadingRepositoryData as they have been implemented in Profile.js
                this.setState({
                    subscriptionStatus: userData.subscriptionStatus
                });
                this.repositoryDataLoaded = true;
                this.loadingRepositoryData = false;
                this.checkAndAutoSubmit();
            });
        }
    }

    checkAndAutoSubmit = () => {
        // If we got redirected from here to Profile and are back here again now after filling the phoneNo, 
        // read the durationInMonths maintained and sent from Profile to this component.
        const routeState = this.props.location.state;
        if (routeState !== undefined) {
            const { from, durationInMonths } = routeState;
            if (from.pathname === Breadcrumbs.getProfileLink()) {
                if (durationInMonths !== undefined) {
                    this.autoSubmitSubscriptionSelection(durationInMonths);
                }
            }
        }
    }

    autoSubmitSubscriptionSelection = async (durationInMonths) => {
        // Since this method is called from componentDidUpdate upating state in this method causes cyclical updates. 
        // (i.e state update causes the method to be called again which in turn causes componentDidUpdate etc. )
        this.checkAndOpenPaymentUrl(durationInMonths, false);
    }

    getSubscriptionAmount = (durationInMonths) => {
        return durationInMonths * 150;
    }

    handleSubscriptionSelection = async (durationInMonths, buttonText) => {
        ReactGA.event({
            category: 'User',
            action: `${buttonText} - Subscription Button Clicked`
        });
        this.checkAndOpenPaymentUrl(durationInMonths, true);
    };

    checkAndOpenPaymentUrl = async (durationInMonths, updateLoadingState) => {
        const { authUser, history } = this.props;
        if (authUser === AUTH_STATE_NOT_SIGNED_IN) {
            history.push(Breadcrumbs.getSignInLink());
            return;
        }
        if (this.phoneNumber === '' || !isphoneNumberValid(this.phoneNumber)
            || this.email === '' || !isEmailValid(this.email)) {
            history.push(Breadcrumbs.getProfileLink(), { from: this.props.location, durationInMonths: durationInMonths });
            return;
        }
        const config = await getConfigWithAuthHeader(authUser);

        this.submitting = true;
        if (updateLoadingState === true) {
            this.setState({
                loading: true
            });
        }
        axios.post(`${BASE_END_POINT}/payment/url`,
            {
                email: this.email,
                phoneNumber: this.phoneNumber,
                subscriptionAmount: this.getSubscriptionAmount(durationInMonths),
                durationInMonths: durationInMonths
            },
            config)
            .then((res) => {
                if (updateLoadingState) {
                    this.setState({
                        loading: false
                    })
                }
                const url = res.data;
                window.Instamojo.open(url);
                this.submitting = false;
            }).catch((err) => {
                console.log(err);
            });
    }

    render() {
        const { classes } = this.props;
        if (this.state.loading) {
            return <Progress />
        }

        return (
            <div>
                <Helmet>
                <title>{getSubscriptionTitle()}</title>
                    <script async={true} src="https://js.instamojo.com/v1/checkout.js"></script>
                </Helmet>
                <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={16}>
                    <Grid item>
                        <Typography component={'h1'} variant={"h4"} align={'center'} color={"textPrimary"} className={classes.heroContent}>Subscription</Typography>
                        <Typography variant={'h6'} align={'center'} color={"textSecondary"}>We are glad to see your continued use.</Typography>
                        <Typography variant={'h6'} align={'center'} color={"textSecondary"}>
                            {this.state.subscriptionStatus === 'EXPIRED' ?
                                'Please renew subscription to access Commodity Prices and Trends.'
                                :
                                'Please subscribe to access Commodity Prices and Trends.'
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={40} style={{ justifyContent: "center" }}>
                            {
                                tiers.map(tier => {
                                    const title = `${tier.durationInMonths} ${tier.durationInMonths > 1 ? 'Months' : 'Month'}`;
                                    const price = this.getSubscriptionAmount(tier.durationInMonths);
                                    return (
                                        <Grid item key={title}>
                                            <Card>
                                                <CardHeader
                                                    title={title}
                                                    subheader={tier.subheader}
                                                    titleTypographyProps={{ align: "center" }}
                                                    subheaderTypographyProps={{ align: "center" }}
                                                    action={tier.title === '3 Months' ? <StarIcon /> : null}
                                                    className={classes.cardHeader}
                                                />
                                                <CardContent>
                                                    <div className={classes.cardPricing}>
                                                        <Typography component={"h2"} variant={"h3"} color="textPrimary" align={"center"}>
                                                            ₹{price}
                                                        </Typography>
                                                    </div>
                                                    {
                                                        tier.description.map(line => {
                                                            return (
                                                                <Typography variant={"subtitle1"} align={"center"} key={line}>
                                                                    {line}
                                                                </Typography>
                                                            )
                                                        })
                                                    }
                                                </CardContent>
                                                <CardActions className={classes.cardActions}>
                                                    <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={() => { this.handleSubscriptionSelection(tier.durationInMonths, tier.buttonText) }} >
                                                        {tier.buttonText}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </div>

        );
    }
}

export default withStyles(styles)(withAuthUser(withRouter(Subscription)));