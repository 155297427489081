import React from 'react';
import { Typography, Grid } from '@material-ui/core';

function PrivacyPolicy(props) {
    return (
        <Grid container direction={"column"} style={{ padding: 16 }}>
            <Grid item>
                <Typography variant={"h5"}>Privacy Policy</Typography>
            </Grid>
            <Grid item>
                <Typography variant={"body2"} component={"div"}>
                    <div>
                        <p>This privacy policy ("Policy") explains how personal
							information is collected, used, and disclosed with respect to your
							use of our website located at www.anvayin.com (the "Site") and the
							software and services provided by Anvayin Systems Pvt Ltd.
							("Anvayin", "we" or "us") and accessed through the Site and your
							mobile device (the "Anvayin Service") so you can make an informed
							decision about using the Anvayin Service. By using the Anvayin
							Service, you represent that you are 13 years old or older and
							consent to the privacy practices described in this Policy, as may
							be modified by us from time to time. We reserve the right to
							change the provisions of this Policy at any time. We will alert
							you that changes have been made by indicating on the top of the
							privacy policy the date it was last updated. Your use of the Site
							following the posting of such changes or revised statement shall
							constitute your consent to any changes in our privacy practices,
							and you expressly waive any right to notice of such changes. We
							encourage you to review this Policy whenever you visit our Site to
							make sure that you understand how any personal information you
							provide will be used.</p>

                        <Typography variant={"h6"}>What Information Do We Collect? </Typography>
                        <p>When you visit the Site you may provide us with two types of
							information: personal information you knowingly choose to disclose
							that is collected on an individual basis and use information
							collected on an aggregate basis as you and others browse the Site.
						</p>

                        <Typography variant={"h6"}>1. Personal Information You Choose to Provide</Typography> 
                        <Typography variant={"h6"}>Registration Information </Typography>
                        <p>You will provide us information about yourself when you
							register to be a user of www.Anvayin.com, register for certain
							services, or register for email newsletters and alerts. We may
							combine information you provide with information from third party
							sources to improve your experience with our Site and Service.</p>
                        <p>You can use the "My Account" section to learn more about the
							information associated with your account. You may also provide
							additional comments on how you see www.Anvayin.com servicing your
							needs and interests.</p>

                        <Typography variant={"h6"}>Communication Information </Typography>
                        <p>If you choose to correspond with us through email, we may
							retain the content of your email messages together with your email
							address and our responses. If you choose to sign up for an email
							newsletter, we will send the newsletter to the email address you
							register with us. If you wish to unsubscribe from the newsletter,
							you may do so by using the unsubscribe link in the newsletter. If
							you choose to communicate via SMS through one of our services that
							offers SMS technology, we may retain the content of the message
							together with the phone number and wireless carrier, as well as
							the date and time the message was received or sent.</p>

                        <Typography variant={"h6"}>Location Data </Typography>
                        <p>If you use any mobile application in connection with the
							Anvayin Service, we may receive information about your actual
							location, or information that can be used to approximate your
							location, including GPS signals sent by your mobile device and
							your mobile identification.</p>


                        <Typography variant={"h6"}>2. Website Use Information</Typography>
                        <p>Similar to other commercial websites, the Site utilizes a
							standard technology called "cookies" (see explanation below, "What
							Are Cookies?") and Web server logs to collect information about
							how the Site is used. Information gathered through cookies and Web
							server logs may include the date and time of visits, the pages
							viewed, time spent at our Site, and the websites visited just
							before and just after our Site. We, our advertisers and ad serving
							companies may also use small pieces of code called "web beacons"
							or "clear gifs" to determine which advertisements and promotions
							users have seen and how users responded to them.</p>

                        <Typography variant={"h6"}>What Are Cookies?</Typography>
                        <p>A cookie is a very small text document, which often includes
							an anonymous unique identifier. When you visit a Web site, that
							site's computer asks your computer for permission to store this
							file in a part of your hard drive specifically designated for
							cookies. Each Web site can send its own cookie to your browser if
							your browser's preferences allow it, but (to protect your privacy)
							your browser only permits a Web site to access the cookies it has
							already sent to you, not the cookies sent to you by other sites.
							You can configure your browser to notify you whenever a cookies is
							being sent, and to refuse all cookies. Please note that some
							features for the Anvayin Service may not function properly if
							cookies are disabled for your browser.</p>

                        <Typography variant={"h6"}>How Do We Use Information We Collect from Cookies?
						</Typography>
                        <p>As you browse through our Site, the Site uses its cookies to
							differentiate you from other Anvayin users. In some cases, we also
							use cookies to prevent you from seeing unnecessary advertisements
							or requiring you to log in more than is necessary for security.
							Cookies, in conjunction with our Web server's log files, allow us
							to calculate the aggregate number of people visiting our Web site
							and which parts of the site are most popular. This helps us gather
							feedback in order to constantly improve our Web site and better
							serve our customers. Cookies do not allow us to gather any
							personal information about you and we do not generally store any
							personal information that you provided to us in your cookies.</p>

                        <Typography variant={"h6"}>Sharing Information with Third Parties </Typography>

                        <p>We may enter into alliances, partnerships or other business
							arrangements with third parties who may be given access to
							personal information including your name, address, telephone
							number and email for the purpose of providing you information
							regarding products and services that we think will be of interest
							to you. In connection with alliances, partnerships or
							arrangements, we may also provide certain information to third
							parties if we have determined that the information will be used in
							a responsible manner by a responsible third party. We may also use
							third parties to facilitate our business, including, but not
							limited to, sending email and processing credit card payments. In
							connection with these offerings and business operations, our
							partners and other third parties may have access to your personal
							information for use in connection with business activities. As we
							develop our business, we may buy or sell assets or business
							offerings. Customer, email, and visitor information is generally
							one of the transferred business assets in these types of
							transactions. We may also transfer such information in the course
							of corporate divestitures, mergers, or any dissolution.</p>

                        <Typography variant={"h6"}>How Do We Use the Information That You Provide to
							Us? </Typography>
                        <p>Broadly speaking, we use personal information for purposes
							of administering and expanding our business activities, providing
							customer service and making available other products and services
							to our users and prospective customers. Occasionally, we may also
							use the information we collect to notify you about important
							changes to our Site, new services and special offers we think you
							will find valuable. You may notify us at any time if you do not
							wish to receive these offers by emailing us at
							support@anvayin.com.</p>



                        <Typography variant={"h6"}>How Do We Protect Your Information? </Typography> <Typography variant={"h6"}>How
							Do We Secure Information Transmissions? </Typography>
                        <p>We take appropriate security measures to protect against
							unauthorized access to all of our data. This includes internal
							reviews of our data collection, storage and processing practices,
							as well as an assessment of our encryption and physical security
							measures to guard against unauthorized access to systems where we
							store personal data.</p>
                        <p>We restrict access to personal information to a limited
							number of our employees, partners and agents. These individuals
							and entities are bound by confidentiality agreements and may be
							subject to discipline, including termination and criminal
							prosecution, if they fail to meet any obligations required under
							their agreement with us.</p>

                        <Typography variant={"h6"}>How Can You Access and Correct Your Information? </Typography>
                        <p>When you use our Site, we make every effort to provide you
							with access to your personal information and will correct this
							data if it is inaccurate or delete it at your request if it is not
							otherwise required to be retained by law or for legitimate
							business purposes. We ask individual users to identify themselves
							and the information requested to be accessed, corrected or removed
							before processing such requests, and we may decline to process
							requests that are unreasonably repetitive or systematic, require
							disproportionate technical effort, jeopardize the privacy of
							others, would be extremely impractical (for instance, requests
							concerning information residing on backup tapes), or for which
							access is not otherwise required. In any case where we provide
							information access and correction, we perform this service free of
							charge, except if doing so would require a disproportionate
							effort. Because of the way we maintain certain services, after you
							delete your information, residual copies may take a period of time
							before they are deleted from our active servers and may remain in
							our backup systems.</p>

                        <Typography variant={"h6"}>Certain Disclosures</Typography>
                        <p>We may disclose your personal information if required to do
							so by law or subpoena or if we believe that such action is
							necessary to (a) conform to the law or comply with legal process
							served on us or Affiliated Parties; (b) protect and defend our
							rights and property, the Site, the users of the Site, and/or our
							Affiliated Parties; (c) act under circumstances to protect the
							safety of users of the Site, us, or third parties.</p>

                        <Typography variant={"h6"}>Enforcement</Typography>
                        <p>We regularly review our compliance with this Privacy Policy.
							We take all of your concerns seriously and will review any written
							complaints received by us. We will cooperate with the appropriate
							regulatory authorities, including local data protection
							authorities, to resolve any complaints regarding the transfer of
							personal data that cannot be resolved between any of our members
							and us.</p>

                        <Typography variant={"h6"}>What About Other Websites Linked to Our Site?</Typography>
                        <p>We are not responsible for the practices employed by
							websites linked to or from our Site nor the information or content
							contained therein. Often, links to other websites are provided
							solely as pointers to information on topics that may be useful to
							the users of our Site.</p>
                        <p>Please remember that when you use a link to go from our Site
							to another website, our Privacy Policy is no longer in effect.
							Your browsing and interaction on any other website, including
							websites which have a link on our Site, is subject to that
							website's own rules and policies. Please read over those rules and
							policies before proceeding.</p>

                        <Typography variant={"h6"}>Transfer of Data </Typography>
                        <p>We process personal information on leased dedicated servers
							located in the United States of America and in other countries. In
							some cases, we process personal information outside your own
							country. If you use the Anvayin Service from outside the United
							States and provide personal information to us, your personal
							information may be transferred to the United States. If you use
							the Anvayin Service from within the United States and provide
							personal information to us, your personal information may be
							transferred out of the United States. Through your use of our
							Service, you consent to such transfer and to the use of the
							information between countries.</p>

                        <Typography variant={"h6"}>Your Consent
						</Typography>
                        <p>By using our Site you consent to our collection and use of your personal information as described in this
							Privacy Policy. If we change our privacy policies and procedures, we will post those changes on our Site to keep
							you aware of what information we collect, how we use it and under what circumstances we may disclose it.
						</p>

                        <p>COPYRIGHT 2013-2019 Anvayin, Inc. All Rights Reserved.</p>
                    </div>
                </Typography>
            </Grid>
        </Grid>

    );
}

export default PrivacyPolicy;