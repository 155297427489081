import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import * as Breadcrumbs from '../Nav/Breadcrumbs';

class SellAdCard extends Component {
    render() {
        const { sellAd } = this.props;

        const handleReadMoreClick = () => {
            const {history} = this.props;
            history.push(Breadcrumbs.getSellAdLink(sellAd.id));
        };

        return (
            <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                    <Typography variant={"h6"} color="textPrimary">{`${sellAd.variety.commodity.name} [${sellAd.variety.name}]`}</Typography>
                    <Typography variant={"h6"} color="textPrimary">{`${sellAd.market.name}`}</Typography>
                    <Typography variant={"subtitle1"} component={"h6"}>{`Price: Rs.${sellAd.price.toLocaleString()}`}</Typography>
                    <Typography variant={"subtitle1"} component={"h6"}>{`Quantity Available: ${sellAd.quantity.toLocaleString()}`}</Typography>
                    <Typography variant={"body1"} >{sellAd.description}</Typography>
                </CardContent>
                <CardActions disableActionSpacing={true} style={{ justifyContent: "flex-end" }}>
                    <Button size="small" onClick={handleReadMoreClick}>View</Button>
                </CardActions>
            </Card>
        );
    }
}

export default withRouter(SellAdCard);