import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavCollection, NavItem } from "../Nav";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { BASE_END_POINT } from '../utils/APIConstants';
import { getCommodityDescription, getCommodityKeywords, getCommodityTitle, Helmet, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';

class CommodityState extends Component {
    render() {
        const { match } = this.props;
        const { stateId, stateName, categoryId, categoryName, commodityId, commodityName } = match.params;
        const resourceUrl = `${BASE_END_POINT}/commodity-market/commodity-id/${commodityId}/state-id/${stateId}/districts`;
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName),
            Breadcrumbs.getCommodityStatesBreadcrumb(categoryId, categoryName, commodityId, commodityName),
            Breadcrumbs.getCommodityStateBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName)
        ];

        const mapFunction = (district) => {
            const linkProps = {
                to: urlFunction(district),
                label: district.name
            }
            return (
                <NavItem linkProps={linkProps} key={district.id} />
            )
        };

        const urlFunction = (district) => {
            return Breadcrumbs.getCommodityDistrictLink(categoryId, categoryName, commodityId, commodityName, stateId, stateName, district.id, district.name);
        }

        return (
            <div>
                <Helmet>
                    <title>{getCommodityTitle(commodityName, stateName)}</title>
                    <meta name={META_DESCRIPTION} content={getCommodityDescription(commodityName, stateName)} />
                    <meta name={META_KEYWORDS} content={getCommodityKeywords(commodityName, stateName)} />
                </Helmet>
                <NavCollection breadcrumbs={breadcrumbs} resourceUrl={resourceUrl} mapFunction={mapFunction} urlFunction={urlFunction} />
            </div>
        );
    }
}

export default withRouter(CommodityState);