import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import { AdSlot } from '../utils';
import { BASE_END_POINT } from '../utils/APIConstants';
import NewsItem from './NewsItem';

class NewsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsItems: []
        }
    }

    cancelToken = null;

    componentDidMount() {
        const { commodityName } = this.props;
        const config = {
            cancelToken: new axios.CancelToken(cancelToken =>
                this.cancelToken = cancelToken
            )
        };

        if (commodityName != null) {
            axios.get(`${BASE_END_POINT}/synd-entries/commodity/${encodeURIComponent(commodityName)}`, config).then(res => {
                this.setState({
                    newsItems: res.data
                });
            }).catch(err => {
                // console.log(err);
            });
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken();
        }
    }

    render() {
        const { commodityName } = this.props;
        const { newsItems } = this.state;
        if (newsItems.length === 0) {
            return null;
        }
        return (
            <Grid container direction={"column"} >
                <Grid item style={{ alignSelf: "center", marginTop: 12, marginBottom: 12 }}>
                    <Typography variant={"h5"} component={"h5"}>News</Typography>
                </Grid>
                <Grid item style={{ marginBottom: 8 }}>
                    {
                        newsItems.map(rssFeed =>
                            <NewsItem rssFeed={rssFeed} key={rssFeed.id} />
                        )
                    }
                </Grid>
                <Grid item >
                    <AdSlot slot='1821245453' responsive='true' slotLocation={`NewsContainer`}/>
                </Grid>
            </Grid>
        );
    }
}

export default NewsContainer;