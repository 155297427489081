import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getCategoryTitle, getCategoryDescription, getCategoryKeywords, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';

class CategoryCommodities extends Component {

    render() {
        const { match } = this.props;
        const { categoryId, categoryName } = match.params;

        const resourceUrl = `${BASE_END_POINT}/category/${categoryId}/commodities`;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName)
        ];

        const mapFunction = (commodity) => {
            const commodityNameParam = encodeURIComponent(commodity.name);
            const linkProps = {
                to: Breadcrumbs.getCommodityStatesLink(categoryId, categoryName, commodity.id, commodityNameParam),
                label: commodity.name
            };
            return <NavItem linkProps={linkProps} key={commodity.id} />
        }

        return (
            <div>
                <Helmet>
                    <title>{getCategoryTitle(categoryName)}</title>
                    <meta name={META_DESCRIPTION} content={getCategoryDescription(categoryName)} />
                    <meta name={META_KEYWORDS} content={getCategoryKeywords(categoryName)} />                    
                </Helmet>
                <NavCollection resourceUrl={resourceUrl} breadcrumbs={breadcrumbs} mapFunction={mapFunction} />

            </div>
        );
    }
}

export default withRouter(CategoryCommodities);