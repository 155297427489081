import React from 'react';
import { uiconfig } from './Firebase';
import { withFirebase } from './FirebaseContext';
import { withAuthUser } from './AuthUserContext';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Grid from "@material-ui/core/Grid";
import { Typography } from '@material-ui/core';
import { getHomeLink } from '../../components/Nav/Breadcrumbs';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Helmet, getSignInTitle } from '../utils/SEOUtils';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { AUTH_STATE_NOT_YET_LOADED, AUTH_STATE_NOT_SIGNED_IN } from '../utils/APIConstants';
import { withRouter } from 'react-router';

class SignIn extends React.Component {

    performUICustomization = (ui) => {
        // console.log(ui);
    }

    componentDidMount() {
        this.checkAndRedirect();
    }

    componentDidUpdate() {
        this.checkAndRedirect()
    }

    checkAndRedirect() {
        const { authUser, history } = this.props;
        if (authUser !== AUTH_STATE_NOT_YET_LOADED && authUser !== AUTH_STATE_NOT_SIGNED_IN) {
            history.push(Breadcrumbs.getProfileLink());
        }
    }

    render() {
        const { firebase, authUser } = this.props;
        if (authUser !== AUTH_STATE_NOT_SIGNED_IN) {
            return (
                <Grid container direction={'column'} alignItems={'center'}>
                    <Grid item>
                        <Typography variant={'body1'}>Welcome, {authUser.displayName}.</Typography>
                    </Grid>
                    <Grid item>
                        <Button component={Link} to={getHomeLink()} color={'secondary'}>
                            {'  <  Home '}
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <div>
                    <Helmet>
                        <title>{getSignInTitle()}</title>
                    </Helmet>
                    <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={16}>
                        <Grid item >
                            <Typography variant={'h6'}>Sign In</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction={'column'} justify={'center'} alignItems={'center'} style={{ marginLeft: 16, marginRight: 16 }}>
                                <Grid item>
                                    <Typography variant={'body1'}>We are glad to see your continued use.</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={'body1'}>Please sign in to view the most recent prices and trends.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <StyledFirebaseAuth uiCallback={this.performUICustomization} uiConfig={uiconfig} firebaseAuth={firebase.auth} />
                        </Grid>
                    </Grid>
                </div>

            );
        }
    }
}

export default withAuthUser(withFirebase(withRouter(SignIn)));