import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Firebase, FirebaseContext } from './components/Auth';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import { withTracker, ErrorBoundry } from './components/utils';


ReactDOM.render(
        <FirebaseContext.Provider value={new Firebase()}>
            <Router>
                <ErrorBoundry>
                    <Route component={withTracker(App)} />
                </ErrorBoundry>
            </Router>
        </FirebaseContext.Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();