import React from 'react';
import { withRouter } from 'react-router';
import { Progress } from '../utils';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { BASE_END_POINT, AUTH_STATE_NOT_YET_LOADED, AUTH_STATE_NOT_SIGNED_IN } from '../utils/APIConstants';
import 'url-search-params-polyfill';
import axios from 'axios';
import { withAuthUser, getConfigWithAuthHeader } from '../Auth';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CheckCircle as CheckIcon, Cancel as CloseIcon } from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = (theme) => (
    {
        heroContent: {
            maxWidth: 600,
            margin: '0 auto',
            padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 2}px`,
        },
        checkIcon: {
            color: green[500]
        },
        closeIcon: {
            color: red[500]
        }
    }
);

class PaymentResponse extends React.Component {
    constructor(props) {
        super(props);
        this.paymentVerificationInProgress = false;
        this.state = {
            paymentVerified: false,
            valid: false
        }
    }
    componentDidMount() {


    }

    async verifyPayment() {
        const { history } = this.props;
        if (this.paymentVerificationInProgress === true) {
            return;
        }
        const { location, authUser } = this.props;
        const params = new URLSearchParams(location.search);
        const paymentId = params.get("payment_id");
        const paymentStatus = params.get("payment_status");
        const paymentRequestId = params.get("payment_request_id");

        const paymentData = {
            paymentId: paymentId,
            paymentRequestId: paymentRequestId,
            paymentStatus: paymentStatus
        }

        if (authUser !== AUTH_STATE_NOT_YET_LOADED && authUser !== AUTH_STATE_NOT_SIGNED_IN) {
            this.paymentVerificationInProgress = true;
            const config = await getConfigWithAuthHeader(authUser);
            const url = `${BASE_END_POINT}/payment/verify`
            axios.post(url, paymentData, config).then((res) => {
                const valid = res.data;
                if (valid) {
                    console.log("Payment has been verified.");
                }
                this.setState({
                    paymentVerified: true,
                    valid: valid
                });
                this.paymentVerificationInProgress = false;
                setTimeout(() => {
                    history.push(Breadcrumbs.getHomeLink());
                }, 3000);
            }).catch((err) => {
                console.log("Error occurred:" + err)
            })
        }
    }

    componentDidUpdate() {
        this.verifyPayment();
    }

    render() {
        const { classes } = this.props;
        return this.state.paymentVerified === false ? <Progress /> :
            <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={16}>
                {this.state.valid ? (
                    <>
                        <Grid item>
                            <Typography component={'h1'} variant={"h4"} align={'center'} color={"textPrimary"} className={classes.heroContent}>Subscription</Typography>
                            <Typography variant={'h6'} align={'center'} color={"textSecondary"}>Thank you for the payment.</Typography>
                            <Typography variant={'h6'} align={'center'} color={"textSecondary"}>You will be redirected to the Home page shortly.</Typography>
                        </Grid>
                        <Grid item>
                            <CheckIcon className={classes.checkIcon} style={{ fontSize: 45, }} align={'center'} />
                        </Grid>
                    </>
                )
                    :
                    <>
                        <Grid item>
                            <Typography component={'h1'} variant={"h4"} align={'center'} color={"textPrimary"} className={classes.heroContent}>Subscription</Typography>
                            <Typography variant={'h6'} align={'center'} color={"textSecondary"}>Your payment failed.</Typography>
                            <Typography variant={'h6'} align={'center'} color={"textSecondary"}>You will be redirected to the Home page shortly.</Typography>
                        </Grid>
                        <Grid item>
                            <CloseIcon className={classes.closeIcon} style={{ fontSize: 45, }} align={'center'} />
                        </Grid>
                    </>
                }
                <Grid item>
                    <Button component={Link} to={Breadcrumbs.getHomeLink()} color={'secondary'}>
                        {'  <  Home '}
                    </Button>
                </Grid>
            </Grid>
    }
}

export default withStyles(styles)(withAuthUser(withRouter(PaymentResponse)));