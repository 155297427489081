import React from 'react';
import { getRecentlyViewedPriceMetadataArray, TYPE_COMMODITY } from '../utils/SubscriptionUtils';
import { NavItem } from "../../components/Nav";
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import * as Breadcrumbs from '../../components/Nav/Breadcrumbs';

class RecentlyViewed extends React.Component {

    getLinkProps = (recentlyViewedPriceMetadata) => {
        let linkProps = null;
        if (recentlyViewedPriceMetadata.type === TYPE_COMMODITY) {
            const commodityId = recentlyViewedPriceMetadata.itemId;
            const commodityName = recentlyViewedPriceMetadata.commodityName;
            const marketId = recentlyViewedPriceMetadata.marketId;
            const stateName = recentlyViewedPriceMetadata.stateName;
            const districtName = recentlyViewedPriceMetadata.districtName;
            const marketName = recentlyViewedPriceMetadata.marketName;

            if (commodityName !== undefined && marketName !== undefined) {
                linkProps = {
                    to: Breadcrumbs.getCommodityMarketPriceLink(commodityId, commodityName, marketId, stateName, districtName, marketName),
                    label: `${commodityName} Prices at ${marketName}`
                }
            }
        } else {
            const varietyName = recentlyViewedPriceMetadata.varietyName;
            const commodityName = recentlyViewedPriceMetadata.commodityName;
            const varietyTitle = varietyName !== commodityName ? `${commodityName}(${varietyName})` : `${commodityName}`;
            const varietyId = recentlyViewedPriceMetadata.itemId;
            const marketId = recentlyViewedPriceMetadata.marketId;
            const stateName = recentlyViewedPriceMetadata.stateName;
            const districtName = recentlyViewedPriceMetadata.districtName;
            const marketName = recentlyViewedPriceMetadata.marketName;

            if (varietyTitle !== undefined && marketName !== undefined) {
                linkProps = {
                    to: Breadcrumbs.getMarketVarietyPriceLink(varietyId, commodityName, varietyName, marketId, stateName, districtName, marketName),
                    label: `${varietyTitle} Prices at ${marketName}`
                }
            }
        }
        return linkProps;
    }

    render() {

        const recentlyViewedPriceMetadataArray = getRecentlyViewedPriceMetadataArray();
        if (recentlyViewedPriceMetadataArray === null) {
            return null;
        }

        const items = recentlyViewedPriceMetadataArray.map((recentlyViewedPriceMetadata, index) => {
            const linkProps = this.getLinkProps(recentlyViewedPriceMetadata);
            if (linkProps === null){
                return null;
            }
            /*
            const gaEventArgs = {
                category: 'Recently Viewed',
                action: `Clicked '${linkProps.label}'.`
            }
            return <NavItem linkProps={linkProps} key={`${recentlyViewedPriceMetadata.type}-${recentlyViewedPriceMetadata.itemId}-${recentlyViewedPriceMetadata.marketId}`} gaEventArgs={gaEventArgs}/>;
            */
           return <NavItem linkProps={linkProps} key={`${recentlyViewedPriceMetadata.type}-${recentlyViewedPriceMetadata.itemId}-${recentlyViewedPriceMetadata.marketId}`} />;
        });
        return (
            <Grid container direction={'column'} spacing={8} alignItems={'center'} justify={'center'} style={{ marginTop: 32 }}>
                <Grid item>
                    <Typography variant={"h6"} component={"h6"}>Recently Viewed</Typography>
                </Grid>

                <Grid item>
                    {items}
                </Grid>
            </Grid>
        );
    }
}

export default RecentlyViewed;