import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { BASE_END_POINT } from '../utils/APIConstants'
import axios from 'axios';
import { withRouter } from "react-router";

const styles = theme => {
    return ({
        error: {
            color: theme.palette.error.dark
        }
    })
};

class ErrorBoundry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    logErrorToServer(error, errorInfo) {
        // subject [error]
        /*
            Client Side Error: Component: AdSlot-MarketCommodityPrice-AfterCommodityPrice: adsbygoogle.push() error: No slot size for availableWidth=0
        */
        // Content [errorInfo]
        /*
            Content: Parent Component Name: AdSlot-MarketCommodityPrice-AfterCommodityPrice, 
            Location: /commodity/Coriander(Leaves)/market/Nagpur.htm, 
            AppName: Netscape, outerHeight: 584, outerWidth: 360, 
            Stack: {"componentStack":"\n in t\n in div\n in t\n in t\n in withRouter(t)\n in t\n in t\n in t\n in div\n in h\n in t\n in div\n in h\n in t\n in div\n in t\n in t\n in withRouter(t)\n in t\n in t\n in t\n in t\n in t\n in withRouter(t)\n in t\n in div\n in h\n in t\n in div\n in h\n in t\n in div\n in div\n in t\n in t\n in t\n in withRouter(t)\n in Unknown\n in t\n in n\n in t\n in t\n in t\n in withRouter(t)\n in t\n in t\n in t"}
        */
        const { location } = this.props;
        const { parentComponentName } = this.props;
        const url = `${BASE_END_POINT}/clientLogs/recordError`;
        const errorToRecord = parentComponentName !== undefined ? `Component: ${parentComponentName}: ${error.message}` : error.message;
        const browserInformation = `AppName: ${navigator.appName}`;
        const windowInformation = `outerHeight: ${window.outerHeight}, outerWidth: ${window.outerWidth}`;
        const errorInfoString = `Location: ${location.pathname}, ${browserInformation}, ${windowInformation}, Stack: ${JSON.stringify(errorInfo)}`;
        const errorInfoToRecord = parentComponentName !== undefined
            ? `Parent Component Name: ${parentComponentName}, ${errorInfoString}` : errorInfoString;
        axios.post(url, {
            error: errorToRecord,
            errorInfo: errorInfoToRecord
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });
    }

    componentDidCatch(error, errorInfo) {
        let adSlotError = false;
        const { parentComponentName } = this.props;
        if (parentComponentName != undefined && parentComponentName != null) {
            if (parentComponentName.startsWith('AdSlot')) {
                adSlotError = true;
            }
        }
        if (adSlotError) {
            // For now ignore the AdSlot errors
        } else {
            this.logErrorToServer(error, errorInfo);
        }

        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        const { classes } = this.props;
        if (this.state.hasError) {
            return (
                <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={16}>
                    <Grid item >
                        <Grid container direction={'row'} alignItems={'center'} spacing={16}>
                            <Grid item>
                                <ErrorIcon className={classes.error} />
                            </Grid>
                            <Grid>
                                <Typography variant={'h6'} className={classes.error}>Oops! Something went wrong!</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                            <Grid item>
                                <Typography variant={'body1'} className={classes.error}>Please report this error to support@anvayin.com</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} className={classes.error}>We will resolve this as soon as possible.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );

        }

        return this.props.children;
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ErrorBoundry));