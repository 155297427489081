export const BASE_TITLE = `Commodity Prices across various markets in India`;
export const BASE_DESCRIPTION = `Anvayin collects, summarizes and presents Commodity Prices from various markets across India for easy consumption`;
export const BASE_KEYWORDS = `Commodity, Pricing, India`;
export const BASE_ROBOTS = `index, follow`;
export const META_DESCRIPTION = 'description';
export const META_KEYWORDS = 'keywords';

export const getTitle = (...keys) => {
    return `Commodity Prices across ${getContactenatedMarketValue(keys)}`;
};
export const getDescription = (...keys) => {
    return `Anvayin collects, summarizes and presents Commodity Prices from various markets across ${getContactenatedMarketValue(keys)} for easy consumption`;
};
export const getKeywords = (...keys) => {
    return `Commodity, Pricing, ${getContactenatedMarketValue(keys)}, India`
};

export const getCategoryTitle = (categoryName) => {
    return `${categoryName} prices across India`;
}

export const getCategoryDescription = (categoryName) => {
    return `Anvayin collects, summarizes and presents ${categoryName} Prices from various markets across India for easy consumption`;
}

export const getCategoryKeywords = (categoryName) => {
    return `${categoryName}, Pricing, India`
}

export const getCommodityTitle = (commodityName, ...marketKeys) => {
    if (commodityName === null) {
        return BASE_TITLE;
    }
    return `${commodityName} prices across ${getContactenatedMarketValue(marketKeys)}`;
}

export const getCommodityDescription = (commodityName, ...marketKeys) => {
    if (commodityName === null) {
        return BASE_DESCRIPTION;
    }
    return `Anvayin collects, summarizes and presents ${commodityName} Prices from various markets across ${getContactenatedMarketValue(marketKeys)} for easy consumption`;
}

export const getCommodityKeywords = (commodityName, ...marketKeys) => {
    if (commodityName === null) {
        return BASE_KEYWORDS;
    }
    return `${commodityName}, Pricing, ${getContactenatedMarketValue(marketKeys)}, India`
};

export const getVarietyTitle = (commodityName, varietyName, ...marketKeys) => {
    if (commodityName === null || varietyName === null) {
        return BASE_TITLE;
    }
    if (commodityName === varietyName) {
        return getCommodityTitle(commodityName, ...marketKeys);
    }
    return `${commodityName}, ${varietyName} prices across ${getContactenatedMarketValue(marketKeys)}`;
};

export const getVarietyDescription = (commodityName, varietyName, ...marketKeys) => {
    if (commodityName === null || varietyName === null) {
        return BASE_DESCRIPTION;
    }
    if (commodityName === varietyName) {
        return getCommodityDescription(commodityName, ...marketKeys);
    }
    return `Anvayin collects, summarizes and presents ${commodityName}, ${varietyName} Prices from various markets across ${getContactenatedMarketValue(marketKeys)} for easy consumption`;
}

export const getVarietyKeywords = (commodityName, varietyName, ...marketKeys) => {
    if (commodityName === null || varietyName === null) {
        return BASE_KEYWORDS;
    }
    if (commodityName === varietyName) {
        return getCommodityKeywords(commodityName, ...marketKeys);
    }
    return `${commodityName}, ${varietyName}, Pricing, ${getContactenatedMarketValue(marketKeys)}, India`
};


export const getContactenatedMarketValue = (keys) => {
    if (keys.length === 0) {
        return 'India';
    }
    let returnValue = keys.reduce((acc, current, index) => index === 0 || acc.length === 0 ? current : acc + ', ' + current);
    return returnValue;
}

export const getSignInTitle = () => {
    return 'Sign in to access ' + BASE_TITLE;
}

export const getSubscriptionTitle = () => {
    return 'Subscribe to access ' + BASE_TITLE;
}

export { Helmet } from 'react-helmet';