import React from 'react';
import { Grid, Typography, TextField, Button, Snackbar, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { withAuthUser, getConfigWithAuthHeader } from '../Auth';
import axios from 'axios';
import { BASE_END_POINT, AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED } from '../utils/APIConstants';
import Progress from '../utils/Progress';
import { withRouter } from "react-router";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import { isphoneNumberValid, isEmailValid, getPhoneNoWithoutCountryCode } from '../utils/SubscriptionUtils';

const styles = (theme) => {
    return ({
        layout: {
            width: 'auto',
            marginLeft: theme.spacing.unit * 2,
            marginRight: theme.spacing.unit * 2,
            [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
                width: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        close: {
            padding: theme.spacing.unit / 2,
        }
    })
}

class Profile extends React.Component {
    constructor(props) {
        super(props);
        const { authUser } = this.props;
        this.repositoryDataLoaded = false;
        this.loadingRepositoryData = false;
        if (authUser === AUTH_STATE_NOT_SIGNED_IN || authUser === AUTH_STATE_NOT_YET_LOADED) {
            this.state = {
                authUser: authUser,
                displayName: '',
                email: '',
                phoneNumber: '',
                loading: true,
                snackbarOpen: false
            };
        } else {
            this.state = {
                authUser: authUser,
                displayName: authUser.displayName === null ? '' : authUser.displayName,
                email: authUser.email === null ? '' : authUser.email,
                phoneNumber: authUser.phoneNumber === null ? '' : getPhoneNoWithoutCountryCode(authUser.phoneNumber),
                snackbarOpen: false
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.authUser === AUTH_STATE_NOT_YET_LOADED
            ||
            nextProps.authUser === AUTH_STATE_NOT_SIGNED_IN) {
            return null;
        }
        if (nextProps.authUser !== prevState.authUser) {
            let phoneNumberFromFirebase = nextProps.authUser.phoneNumber;
            phoneNumberFromFirebase = getPhoneNoWithoutCountryCode(phoneNumberFromFirebase);
            return {
                authUser: nextProps.authUser,
                displayName: prevState.displayName === '' ?
                    (nextProps.authUser.displayName === null ? '' : nextProps.authUser.displayName) : prevState.phoneNumber,
                email: prevState.email === '' ?
                    (nextProps.authUser.email === null ? '' : nextProps.authUser.email)
                    : prevState.email,
                phoneNumber: prevState.phoneNumber === '' ?
                    (phoneNumberFromFirebase === null ? '' : phoneNumberFromFirebase)
                    : prevState.phoneNumber
            };
        }
        else {
            return null;
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        this.loadRepositoryData();
    }

    componentDidUpdate() {
        this.loadRepositoryData();
    }

    // TOTO: Handle Facebook no email login scenario. (probably phone number based login)
    // Get the email from local repository if user has saved earler.
    async loadRepositoryData() {
        if ((this.state.authUser !== AUTH_STATE_NOT_SIGNED_IN
            && this.state.authUser !== AUTH_STATE_NOT_YET_LOADED)
            && this.repositoryDataLoaded === false) {
            if (this.loadingRepositoryData === false) {
                const config = await getConfigWithAuthHeader(this.state.authUser);
                const url = `${BASE_END_POINT}/users/currentUserWithAttributes`;
                this.loadingRepositoryData = true;
                axios.get(url, config).then((res) => {
                    this.setStateFromResponse(res);
                    this.repositoryDataLoaded = true;
                    this.loadingRepositoryData = false;
                }).catch((err) => {
                    this.repositoryDataLoaded = true;
                    this.loadingRepositoryData = false;
                    this.setState({
                        loading: false
                    });
                    console.log(err);
                });
            }
        }
    }

    setStateFromResponse = (res) => {
        const userData = res.data;
        // Since the other fields are read only set only phone number and allow users to update phone number.
        this.setState({
            displayName: userData.firstName === null ? '' : userData.firstName,
            phoneNumber: userData.phoneNumber === null ? '' : userData.phoneNumber,
            email: userData.email === null ? '' : userData.email,
            loading: false
        })
    }

    changeTextArea = () => {
        // console.log(this.state);
    }

    submitFormWithAuth = async (values, formikBag) => {
        if (this.state.authUser === AUTH_STATE_NOT_SIGNED_IN ||
            this.state.authUser === AUTH_STATE_NOT_YET_LOADED) {
            return;
        }
        const config = await getConfigWithAuthHeader(this.state.authUser);
        this.setState({
            loading: true
        })
        const formData = {
            displayName: values.displayName,
            phoneNumber: values.phoneNumber,
            email: values.email
        }
        const url = `${BASE_END_POINT}/users/saveAttributes`;
        axios.post(url, formData, config).then((res) => {
            this.setStateFromResponse(res);
            formikBag.setSubmitting(false);
            this.openSnackbar();
            this.redirect();
        });
    }

    redirect = () => {
        const { history } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        if (from.pathname === '/') {
            setTimeout(() => { history.push(Breadcrumbs.getHomeLink()); }, 3000);
        } else if (from.pathname === '/subscription') {
            // durationInMonths is passes from subscription component.
            const { durationInMonths } = this.props.location.state;
            const routeState = { from: this.props.location, durationInMonths: durationInMonths };
            history.push(from.pathname, routeState);
        }
    }

    handleSubmit = (values, formikBag) => {
        this.submitFormWithAuth(values, formikBag);
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            snackbarOpen: false
        });
    }

    openSnackbar = () => {
        this.setState({
            snackbarOpen: true
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container direction={"column"} spacing={24} className={classes.layout} justify={"center"}>
                <Grid item style={{ alignSelf: "center" }}>
                    <Typography variant={"h6"} gutterBottom>Update your profile</Typography>
                </Grid>
                {this.state.loading ?
                    <Progress />
                    :
                    <Grid item>
                        <Formik
                            initialValues={{
                                displayName: this.state.displayName,
                                email: this.state.email,
                                phoneNumber: this.state.phoneNumber
                            }}
                            // Explore 'Yup' for advanced validaton.
                            validate={values => {
                                let errors = {};
                                if (!values.displayName) {
                                    errors.displayName = 'Required';
                                }
                                if (!values.phoneNumber) {
                                    errors.phoneNumber = 'Required';
                                }
                                else if (!isphoneNumberValid(values.phoneNumber)) {
                                    errors.phoneNumber = 'Invalid Phone Number';
                                }
                                if (!values.email) {
                                    errors.email = 'Required';
                                }
                                else if (!isEmailValid(values.email)) {
                                    errors.email = 'Invalid eMail';
                                }
                                return errors;
                            }}
                            onSubmit={(values, formikBag) => {
                                this.handleSubmit(values, formikBag);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={24} direction={"column"}>
                                            <Grid item xs={12}>
                                                {errors.displayName
                                                    && touched.displayName
                                                    && <Typography color={'error'}>{errors.displayName}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.displayName
                                                        && touched.displayName}
                                                    id="displayName"
                                                    name="displayName"
                                                    label="displayName"
                                                    type="text"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.displayName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        readOnly: this.state.displayName === '' ? false : true,
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {errors.email
                                                    && touched.email
                                                    && <Typography color={'error'}>{errors.email}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.email
                                                        && touched.email}
                                                    id="email"
                                                    name="email"
                                                    label="eMail"
                                                    type="email"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    InputProps={{
                                                        readOnly: this.state.email === '' ? false : true,
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {errors.phoneNumber
                                                    && touched.phoneNumber
                                                    && <Typography color={'error'}>{errors.phoneNumber}</Typography>}
                                                <TextField
                                                    required
                                                    error={errors.phoneNumber
                                                        && touched.phoneNumber}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    label="Phone Number"
                                                    type="text"
                                                    fullWidth
                                                    autoComplete={"off"}
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant={"contained"} color={"primary"} type={"submit"} fullWidth disabled={isSubmitting}>{'Save'}</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )

                            }

                        </Formik>
                    </Grid>
                }
                {/*
                    <div>
                        <textarea value={JSON.stringify(this.state, undefined, 2)} onChange={this.changeTextArea} style={{ width: 500, height: 500 }}></textarea>
                    </div>
                */}
                <div style={{ width: 200 }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Profile Saved</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleSnackbarClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>
            </Grid>
        );
    }
}

export default withRouter(withAuthUser(withStyles(styles)(Profile)));