import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Business as BusinessIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Home as HomeIcon, ImportExport as ImportExportIcon, LocalFlorist as LocalFloristIcon, Person as PersonIcon, Place as PlaceIcon, ArrowBack as SignInIcon, ArrowForward as SignOutIcon } from '@material-ui/icons';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withAuthUser } from './components/Auth';
import { AUTH_STATE_NOT_SIGNED_IN } from './components/utils/APIConstants';

const drawerWidth = 240;

const styles = theme => {
    return ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        }
    })
};

class AnvayinDrawer extends React.Component {
    render() {
        const { classes, theme, open, handleDrawerClose, handleSignOut, authUser } = this.props;
        return (
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />

                <List>
                    <ListItem button component={Link} to="/" onClick={handleDrawerClose}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItem>
                    <ListItem button component={Link} to="/states" key={'states'} onClick={handleDrawerClose}>
                        <ListItemIcon><ImportExportIcon /></ListItemIcon>
                        <ListItemText primary={'Markets'} />
                    </ListItem>
                    <ListItem button component={Link} to="/categories" key={'categories'} onClick={handleDrawerClose}>
                        <ListItemIcon><LocalFloristIcon /></ListItemIcon>
                        <ListItemText primary={'Commodities'} />
                    </ListItem>
                    <ListItem button key={'about'} component={Link} to="/aboutUs" onClick={handleDrawerClose}>
                        <ListItemIcon><BusinessIcon /></ListItemIcon>
                        <ListItemText primary={'About Us'} />
                    </ListItem>
                    <ListItem button component={Link} to="/contact" onClick={handleDrawerClose}>
                        <ListItemIcon><PlaceIcon /></ListItemIcon>
                        <ListItemText primary={'Contact Us'} />
                    </ListItem>
                </List>
                <Divider />
                {
                    authUser === AUTH_STATE_NOT_SIGNED_IN ?
                        <List>
                            <ListItem button component={Link} to="/signIn" key={'signIn'} onClick={handleDrawerClose}>
                                <ListItemIcon><SignInIcon /></ListItemIcon>
                                <ListItemText primary={'Sign In'} />
                            </ListItem>
                        </List>
                        :
                        <List>
                            <ListItem button component={Link} to="/sell-ad" key={'sell-ad'} onClick={handleDrawerClose}>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText primary={'Sell Ads'} />
                            </ListItem>
                            <ListItem button component={Link} to="/profile" key={'profile'} onClick={handleDrawerClose}>
                                <ListItemIcon><PersonIcon /></ListItemIcon>
                                <ListItemText primary={authUser.displayName !== null? authUser.displayName : authUser.email !== null ? authUser.email: ''} />
                            </ListItem>
                            <ListItem button component={Link} to="/" key={'signOut'} onClick={handleSignOut}>
                                <ListItemIcon><SignOutIcon /></ListItemIcon>
                                <ListItemText primary={'Sign Out'} />
                            </ListItem>
                        </List>
                }

            </Drawer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withAuthUser(withRouter(AnvayinDrawer)));