import React from "react";
import { Typography, Grid } from '@material-ui/core';

function TermsAndConditions(props) {
    return (
        <Grid container direction={"column"} style={{ padding: 16 }}>
            <Grid item>
                <Typography variant={"h5"}>Terms and Conditions</Typography>
            </Grid>
            <Grid item>
                <Typography variant={"body2"} component={"div"}>
                    <div>
                        <p>Welcome to www.anvayin.com, a website owned and operated by
                            Anvayin Systems Pvt Ltd ("Anvayin", "we" or "us"). The following
                            page explains the terms and conditions by which you may use our
                            web site, mobile device software applications, and any other
                            mobile or web services or applications owned, controlled, or
                            offered by Anvayin and its affiliates (collectively, the "Site").
                            By accessing or using the Site, you represent that you have read,
                            understood and agree to be bound by the following terms of use
                            agreement (the "Terms of Use Agreement"). Please read the
                            Agreement carefully. In addition, when you use any current or
                            future Anvayin service or visit or purchase from any business
                            affiliated with us, whether or not included in the www.Anvayin.com
                            website, you also will be subject to the terms and conditions
							applicable to such service or business.</p>

                        <Typography variant={"h6"}>Acceptance of Agreement</Typography>

                        <p>Your use of the Site constitutes your acceptance of the
                            terms and conditions outlined in this Terms of Use Agreement and
                            in each of our policies posted on our website. This Terms of Use
                            Agreement, together with each of our policies posted on our
                            website, are collectively referred to herein as the "Site
                            Policies." The Terms of Use Agreement constitutes the entire and
                            only agreement between us and you concerning the subject matter of
                            the Terms of Use Agreement and supersedes all prior or
                            contemporaneous agreements, representations, warranties and
                            understandings concerning the subject matter of the Terms of Use
                            Agreement. We reserve the right to make changes to our Site, this
                            Terms of Use Agreement and the Site Policies from time to time at
                            our sole discretion. We may make such revisions without prior
                            notice to you, so please check back often for updates. Unless
                            otherwise provided in such revision, any revision to our Site,
                            this Terms of Use Agreement or the Site Policies, or part thereof,
							will take effect when they are posted.</p>

                        <Typography variant={"h6"}>Privacy</Typography>

                        <p>Please review our Privacy Policy, which also governs your
                            visit to our Site, to understand our practices concerning privacy
							matters.</p>

                        <Typography variant={"h6"}>Electronic Communications </Typography>

                        <p>When you visit www.Anvayin.com or send e-mails to us, you
                            are communicating with us electronically. You acknowledge that you
                            can access information that is provided electronically, and you
                            consent to receive communications from us electronically. We will
                            communicate with you by e-mail or by posting notices on this
                            website. You agree that all agreements, notices, disclosures and
                            other communications that we provide to you electronically satisfy
							any legal requirement that such communications be in writing.</p>

                        <Typography variant={"h6"}>Our Services </Typography>

                        <p>We provide you with the opportunity to get information about
                            commodity prices and price analysis of commodities on the Site
							(the "Services").</p>

                        <Typography variant={"h6"}>Registration </Typography>

                        <p>When you register on our Site for the use of our Services,
                            you agree to provide true, accurate, current and complete
                            information about yourself as required by our registration
                            application, and to maintain and promptly update the information
                            provided by you in order to keep it true, accurate, current and
                            complete. You further agree that you are creating the account for
                            yourself, and not for a third party. In addition, you agree that
                            you will comply with all policies of the Site, including this
                            Terms of Service. You agree to provide accurate data and
                            information when using the Site, and that you answer all
                            questions, surveys and complete all tasks to the best of your
                            ability and using information that you believe to be truthful. You
                            agree not to use the Services or access the Site with malicious
                            intent. By using the Site, you represent that you are over the age
                            of 13. If you do not comply with the foregoing conditions, or we
                            reasonably suspect that information provided by you is in
                            violation of the foregoing conditions, we have the right to
                            suspend or terminate your account and refuse any and all current
							or future use of our Services (or any portion thereof).</p>

                        <p>To register for an account online, you must have a valid
                            email address. In addition to your email address, we ask that you
                            provide us with your first and last name, and a password. We may
                            also ask for LinkedIn, Twitter and any other social media profiles
                            you may have and we may use your personal information from those
                            sites. We assume no responsibility for the timeliness, deletion,
                            misdelivery or failure to store any user communications or
                            personalization settings. You are responsible for obtaining access
                            to our Services, and that access may involve third-party fees
                            (such as Internet service provider or airtime charges). If you use
                            your mobile device to access our Site or use our Services, you
                            understand that you are responsible for any fees that your mobile
                            service provider charges for data or SMS services. In addition,
                            you must provide and are responsible for all equipment necessary
							to access our Services.</p>

                        <Typography variant={"h6"}>Account Security </Typography>

                        <p>Once you complete our registration application, you will
                            receive a password and account designation. It is your
                            responsibility to maintain the confidentiality of your password.
                            If you use this Site, you agree to accept responsibility for all
                            activities that occur under your account or password. You agree to
                            notify us immediately of any unauthorized use of your account or
                            if you feel your password has been compromised, or for any other
                            breach of security. To better maintain account security, be sure
                            to log out from your account at the end of each session. We cannot
                            and will not be liable for any loss or damage arising from your
							failure to comply with this section.</p>
                        <p>We reserve the right to refuse service, terminate accounts,
							remove or edit content, or cancel orders at our sole discretion.</p>
                        <p>If you feel that your account has been compromised, contact
							us immediately at support@anvayin.com.</p>

                        <Typography variant={"h6"}>Nontransferable </Typography>

                        <p>Your right to use our website is not transferable or
                            assignable. Any password or right given to you to obtain
                            information or access the Site is not transferable or assignable.
						</p>

                        <Typography variant={"h6"}>Copyright </Typography>

                        <p>The compilation of all content on the Site is the property
                            of Anvayin and is protected by applicable copyright laws. All
                            software used on the Site is the property of Anvayin or its
                            software suppliers and is protected by applicable copyright laws.
						</p>

                        <Typography variant={"h6"}>Commodities Pricing Data </Typography>

                        <p>At present we obtain the commodities pricing information
							from the Open Government Data Platform India <a style={{ color: "blue" }} href="http://data.gov.in" target="new">http://data.gov.in</a>. 
                            We wish to acknowledge that the original source of this
							information is <a style={{ color: "blue" }} href="http://agmarknet.nic.in" target="new">http://agmarknet.nic.in</a>&nbsp; 
                            operated by the Directorate
                            of Marketing &amp; Inspection (DMI), Ministry of Agriculture,
							Government of India.</p>

                        <Typography variant={"h6"}>Trademarks</Typography>

                        <p>The Anvayin name, Anvayin logo and others are our service
                            marks or registered service marks or trademarks. Other products
                            and company names mentioned on the Site may be trademarks of their
                            respective owners. Anvayin's service marks, trademarks and trade
                            dress may not be used in any manner that is likely to cause
                            confusion among customers or in any manner that disparages or
                            discredits Anvayin or www.Anvayin.com. All other trademarks not
                            owned by Anvayin or its subsidiaries that appear on the Site are
                            the property of their respective owners, who may or may not be
                            affiliated with, connected to, or sponsored by Anvayin or its
							subsidiaries.</p>

                        <Typography variant={"h6"}>License and Site Access </Typography>

                        <p>Anvayin grants you a limited, nonexclusive, nontransferable,
                            revocable license to access and make personal use of this Site and
                            not to download (other than page caching) or modify it, or any
                            portion of it, except with our express written consent. This
                            license does not include any resale or commercial use of this Site
                            or its contents; any collection and use of any content; any
                            derivative use of this Site or its contents; any downloading or
                            copying of account information for the benefit of a third party;
                            or any use of data mining, robots, or similar data gathering and
                            extraction tools. This Site or any portion of this Site may not be
                            reproduced, duplicated, copied, sold, resold, visited, or
                            otherwise exploited for any commercial purpose without express
                            written consent of Anvayin. You may not remove, decompile,
                            disassemble or reverse engineer any Site software or use any
                            network monitoring or discovery software to determine the Site
                            architecture. You may not frame or utilize framing techniques to
                            enclose any trademark, logo, or other proprietary information
                            (including images, text, page layout, or form) of Anvayin's and
                            our affiliates without express written consent. You may not access
                            the Site in order to (a) build a competitive commercial product or
                            service, or (b) copy any features, functions or graphics of our
                            Services. You may not use any meta tags or any other "hidden text"
                            utilizing our name or trademarks without the express written
                            consent of Anvayin. You may not use the Site for the purpose of
                            gathering information for or transmitting unsolicited commercial
                            phone calls, facsimile transmissions, email or email that makes
                            use of headers, invalid or nonexistent domain names, or other
                            means of deceptive addressing. You may not use the Site in a
                            manner that violates any local, state, national and international
                            laws or regulations concerning email, telephone solicitations or
                            facsimile transmissions. You may not use this Site to
                            intentionally or unintentionally violate any applicable local,
                            state, national or international law. Any unauthorized use
                            terminates the permission or license granted by Anvayin. You are
                            granted a limited, nonexclusive, nontransferable, revocable right
                            to create a hyperlink to the home page of the Site so long as the
                            link does not portray the Site, Anvayin, its affiliates, or their
                            products or services in a false, misleading, derogatory, or
							otherwise offensive manner.</p>

                        <Typography variant={"h6"}>Reviews, Comments, Communications and Other
							Content </Typography>

                        <p>Visitors may post reviews, comments, and other content; send
                            messages and other communications; and submit suggestions, ideas,
                            comments, questions, or other information ("Content"), so long as
                            the Content is not illegal, obscene, threatening, defamatory,
                            invasive of privacy, infringing of intellectual property rights,
                            in violation of any contractual or fiduciary relationships, or
                            otherwise injurious to third parties or objectionable and does not
                            consist of or contain software viruses, malicious computer code,
                            files or software, political campaigning, commercial solicitation,
                            chain letters, mass mailings, or any form of "spam." You may not
                            use a false e-mail address, forge headers or manipulate
                            identifiers, impersonate any person or entity, or otherwise
                            mislead as to the origin of the Content. You agree not to act in a
                            manner that negatively affects other users' ability to use our
                            Site or Services, nor to harass or "stalk" another user. You agree
                            not to interfere or disrupt our Services or servers or networks
                            connected to our Services, or disobey any requirements,
                            procedures, policies or regulations of networks connected to our
                            Services, including using any device, software or routine to
                            bypass our robot exclusion headers. Anvayin reserves the right
                            (but not the obligation) to remove or edit such Content, but does
							not regularly review posted content.</p>

                        <p>You understand that all Content, whether publicly posted or
                            privately transmitted, is the sole responsibility of the person
                            from whom such Content originated. This means that you are solely
                            responsible for all Content that you upload, post, email, transmit
                            or otherwise make available while using our Services. We do not
                            control the Content posted through our Services and, as such, do
                            not guarantee the accuracy, integrity or quality of such Content.
                            You understand that by using our Services, you may be exposed to
                            Content that is offensive, indecent or objectionable. We are not
                            liable for any Content under any circumstances, including, but not
                            limited to, any errors or omissions in any Content, or any loss or
                            damage of any kind incurred as a result of the use of any Content
                            posted, emailed, transmitted or otherwise made available through
							our Services.</p>

                        <p>If you do post Content or submit material, and unless we
                            indicate otherwise, you grant Anvayin and its affiliates a
                            nonexclusive, royalty-free, perpetual, irrevocable, and fully
                            sublicensable right to use, reproduce, modify, adapt, publish,
                            translate, create derivative works from, distribute, and display
                            such content throughout the world in any media. You grant Anvayin
                            and its affiliates and sublicensees the right to use the name that
                            you submit in connection with such Content, if they choose. You
                            represent and warrant that you own or otherwise control all of the
                            rights to the Content that you post; that the Content is accurate;
                            that use of the Content you supply does not violate this policy
                            and will not cause injury to any person or entity; and that you
                            will indemnify Anvayin or its affiliates for all claims resulting
                            from Content you supply. We have the right but not the obligation
                            to monitor and edit or remove any activity or Content. We take no
                            responsibility and assume no liability for any Content posted by
							you or any third party.</p>

                        <Typography variant={"h6"}>Unlawful Activity </Typography>
                        <p>We reserve the right to investigate complaints or reported
                            violations of the Site Policies and to take any action we deem
                            appropriate, including but not limited to reporting any suspected
                            unlawful activity to law enforcement officials, regulators, or
                            other third parties and disclosing any information necessary or
                            appropriate to such persons or entities relating to your profile,
                            email addresses, usage history, posted materials, IP addresses and
							traffic information.</p>

                        <Typography variant={"h6"}>Removal of Account </Typography>
                        <p>You may request removal of your account, any associated
                            email address and access to our Services by submitting a removal
							request to us.</p>
                        <p>We may, without prior notice, immediately delete, limit your
                            access to or suspend your account and access to our Services.
                            Cause for such action shall include, but not be limited to, any
                            breaches or violations of the Agreement or the Site Policies or
                            other agreements or guidelines on the Site, requests by law
                            enforcement or other government agencies, discontinuance or
                            material modification to our Services (or any part thereof),
                            unexpected technical or security issues or problems, extended
                            periods of inactivity, your engagement in fraudulent or illegal
                            activities, and/or nonpayment of any fees owed by you in
                            connection with our Services. All deletions, limitations of access
                            and account suspensions shall be made at our sole discretion and
                            we are not liable to you or any third party for the deletion of
							your account or access to our Services.</p>
                        <p>Removal of your account includes any or all of the
                            following: (a) removal of access to all or part of the offerings
                            within our Services, (b) deletion of your password and all related
                            information (or any part thereof), and (c) barring of further use
							of all or part of our Services.</p>


                        <Typography variant={"h6"}>Securities Laws </Typography>
                        <p>Our website may include statements concerning our
                            operations, prospects, strategies, financial condition, future
                            economic performance and demand for our products or services, as
                            well as our intentions, plans and objectives (particularly with
                            respect to product and service offerings), and those of other
                            companies and industries that are forward-looking statements.
                            These statements are based upon a number of assumptions and
                            estimates which are subject to significant uncertainties, many of
                            which are beyond our control. When used on our Site, words like
                            "anticipates," "expects," "believes," "estimates," "seeks,"
                            "plans," "intends," "will" and similar expressions are intended to
                            identify forward-looking statements designed to fall within
                            securities law safe harbors for forward-looking statements. Our
                            website and the information contained on the Site does not
                            constitute an offer or a solicitation of an offer for sale of any
							securities.</p>

                        <Typography variant={"h6"}>Information and Press Releases </Typography>
                        <p>Our website contains information and press releases about
                            us. We disclaim any duty or obligation to update this information
                            or any such press releases. Information about companies other than
                            ours contained in any press release or otherwise, should not be
							relied upon as being provided or endorsed by us.</p>

                        <Typography variant={"h6"}>Disclaimer Of Warranties And Limitation Of
							Liability </Typography>
                        <p>THIS SITE IS PROVIDED BY Anvayin ON AN "AS IS" AND "AS
                            AVAILABLE" BASIS. Anvayin MAKES NO REPRESENTATIONS OR WARRANTIES
                            OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE
                            OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON
                            THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT
							YOUR SOLE RISK.</p>
                        <p>TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, Anvayin
                            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
                            A PARTICULAR PURPOSE. Anvayin DOES NOT WARRANT THAT THIS SITE, ITS
                            SERVERS, OR E-MAIL SENT FROM WWW.Anvayin.COM ARE FREE OF VIRUSES
                            OR OTHER HARMFUL COMPONENTS. Anvayin WILL NOT BE LIABLE FOR ANY
                            DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING,
                            BUT NOT LIMITED TO INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
                            DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS,
                            LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT,
                            BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY
                            OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE
                            FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND
                            YOU. THIS SITE AND THE PRODUCTS, SERVICES, AND INFORMATION
							PRESENTED WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.</p>
                        <p>WE AND OUR AFFILIATES SHALL NOT BE LIABLE FOR ANY LOSS,
                            INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING IN ANY
                            WAY FROM (A) ANY ERRORS IN OR OMISSIONS FROM THE SITE OR ANY
                            SERVICES OR PRODUCTS OBTAINABLE FROM THE SITE, (B) THE
                            UNAVAILABILITY OR INTERRUPTION OF THE SITE OR ANY FEATURES OF THE
                            SITE, (C) YOUR USE OF THE SITE OR WITH THE DELAY OR INABILITY TO
                            USE THE SITE, (D) THE CONTENT CONTAINED ON THE SITE, OR (E) ANY
                            DELAY OR FAILURE IN PERFORMANCE BEYOND OUR CONTROL.THE AGGREGATE
                            LIABILITY OF US AND OUR AFFILIATES IN CONNECTION WITH ANY CLAIM
                            ARISING OUT OF OR RELATING TO THE SITE, OR THE PRODUCTS, SERVICES
                            OR INFORMATION PROVIDED BY THE SITE SHALL NOT EXCEED $100 AND THAT
                            AMOUNT SHALL BE IN LIEU OF ALL OTHER REMEDIES WHICH YOU MAY HAVE
							AGAINST US AND OUR AFFILIATES.</p>
                        <p>ANY CAUSE OF ACTION YOU MAY HAVE WITH RESPECT TO THE SITE OR
                            ANY PRODUCTS OR SERVICES PURCHASED USING THE SITE MUST BE BROUGHT
                            BY YOU WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ARISES OR BE
							FOREVER WAIVED AND BARRED.</p>

                        <Typography variant={"h6"}>Indemnification </Typography>
                        <p>You agree to indemnify, defend and hold us and our partners,
                            agents, officers, directors, employees, subcontractors, successors
                            and assigns, third party suppliers of information and documents,
                            attorneys, advertisers, product and service providers and
                            affiliates harmless from any liability, loss, claim and expense,
                            including reasonable attorney's fees, related to your violation of
							the Site Policies or use of our website.</p>

                        <Typography variant={"h6"}>Applicable Law &amp; Exclusive Jurisdiction </Typography>
                        <p>By visiting www.Anvayin.com, you agree that the laws of the
                            state of Tamil Nadu, without regard to principles of conflict of
                            laws, will govern the Site Policies and any dispute of any sort
                            that might arise between you and Anvayin or its affiliates.By
                            using this service, you consent to the exclusive jurisdiction of
                            the courts in Tamil Nadu in all disputes arising out of or
							relating to this agreement or use of our web site.</p>

                        <Typography variant={"h6"}>Miscellaneous</Typography>
                        <p>The language in the Terms of Use Agreement shall be
                            interpreted as to its fair meaning and not strictly for or against
                            any party. The Terms of Use Agreement, including any Content or
                            Published Information provided by you on the Site may be
                            automatically assigned by us in our sole discretion to a third
                            party in the event of an acquisition, sale or merger. Should any
                            part of the Terms of Use Agreement be held invalid or
                            unenforceable, that portion shall be construed consistent with
                            applicable law and the remaining portions shall remain in full
                            force and effect. To the extent that anything in or associated
                            with the Site is in conflict or inconsistent with the Terms of Use
                            Agreement, the Terms of Use Agreement shall take precedence. Our
                            failure to enforce any provision of the Terms of Use Agreement
                            shall not be deemed a waiver of such provision nor of the right to
                            enforce such provision. Our rights under the Terms of Use
                            Agreement shall survive any termination of the Terms of Use
							Agreement.</p>

                        <Typography variant={"h6"}>Other Site Policies and Modification </Typography>
                        <p>From time to time, we may post revised versions of existing
                            policies or new policies on our website. Please review all of our
                            other policies posted on this Site. These policies also govern
                            your visit to www.Anvayin.com. We reserve the right to make
                            changes to our Site, policies, and this Terms of Use Agreement at
							any time.</p>

                        <Typography variant={"h6"}>Notice and Procedure for Making Claims of
							Copyright Infringement </Typography>
                        <p>If you believe that your work has been copied in a way that
                            constitutes copyright infringement, please provide Anvayin's
                            copyright agent the written information specified below. Please
                            note that this procedure is exclusively for notifying Anvayin and
                            its affiliates that your copyrighted material has been infringed.
						</p>
                        <ol>
                            <li>An electronic or physical signature of the person
                                authorized to act on behalf of the owner of the copyright
								interest;</li>
                            <li>A description of the copyrighted work that you claim has
								been infringed upon;</li>
                            <li>A description of where the material that you claim is
								infringing is located on the Site;</li>
                            <li>Your address, telephone number, and e-mail address;</li>
                            <li>A statement by you that you have a good-faith belief that
                                the disputed use is not authorized by the copyright owner, its
								agent, or the law;</li>
                            <li>A statement by you, made under penalty of perjury, that
                                the above information in your notice is accurate and that you are
                                the copyright owner or authorized to act on the copyright owner’s
								behalf.</li>
                        </ol>

                        <p>Anvayin’s Copyright Agent for notice of claims of copyright
							infringement on its website can be reached as follows:</p>
                        <p>E-mail: support@Anvayin.com</p>
                        <p>Effective Date: June 1, 2013</p>
                        <p>COPYRIGHT 2013-2019 Anvayin Systems Pvt Ltd. All Rights
							Reserved.</p>
                    </div>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default TermsAndConditions;