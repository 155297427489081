import React, { Component } from 'react';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";

class Categories extends Component {
    getBreadcrumbs() {
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb()
        ];
        return breadcrumbs;
    }


    render() {
        const categoriesResourceUrl = `${BASE_END_POINT}/categories`;
        const breadcrumbs = this.getBreadcrumbs();
        const mapFunction = (category) => {
            const categoryNameParam = encodeURIComponent(category.name);
            const linkProps = {
                to: Breadcrumbs.getCategoryCommoditiesLink(category.id, categoryNameParam),
                label: category.name
            };
            return <NavItem linkProps={linkProps} key={category.id} />
        }

        return (
            <NavCollection breadcrumbs={breadcrumbs} resourceUrl={categoriesResourceUrl} mapFunction={mapFunction} />
        );

    }
}

export default Categories;