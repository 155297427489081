import React from 'react';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";

const END_POINT = `${BASE_END_POINT}/states`;

class States extends React.Component {
    constructor(props) {
        super(props);
        this.resourceUrl = END_POINT;
    }

    componentDidUpdate() {
        // const backButtonNavigation = this.props.history.action === "POP";
        // console.log('backButtonNavigation', backButtonNavigation);
        // if (backButtonNavigation === false){
        window.scrollTo(0, 0);
        // }
    }

    getBreadcrumbs() {
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getStatesBreadcrumb()
        ];
        return breadcrumbs;
    }

    render() {
        const resourceUrl = this.resourceUrl;
        const breadcrumbs = this.getBreadcrumbs();
        const mapFunction = (state) => {
            const linkProps = {
                to: Breadcrumbs.getStateLink(state.id, state.name),
                label: state.name
            };
            return (
                <NavItem linkProps={linkProps} key={state.id} />
            );
        }

        return (
            <div>
                <NavCollection breadcrumbs={breadcrumbs} resourceUrl={resourceUrl} mapFunction={mapFunction} />
            </div>
        );
    }
}
export default States;