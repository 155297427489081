import axios from 'axios';
import React from 'react';
import { withRouter } from "react-router";
import { BASE_END_POINT } from '../utils/APIConstants';
import { NavCollection, NavItem } from "../Nav";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { Progress } from '../utils';

class VarietyStates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variety: null
        }
        const { match } = this.props;
        const { params } = match;
        this.varietyId = params.varietyId;
    }

    componentDidMount() {
        const varietyResourceUrl = `${BASE_END_POINT}/varieties/${this.varietyId}`;
        axios.get(varietyResourceUrl).then(res => {
            this.setState({
                variety: res.data
            })
        });
    }

    getBrearcrumbs(variety) {
        if (variety === null) {
            return [];
        }
        const commodity = variety.commodity;
        const breadCrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getVarietyStatesBreadcrumb(variety.id, commodity.name, variety.name)
        ];
        return breadCrumbs;
    }

    render() {
        const resourceUrl = `${BASE_END_POINT}/variety-markets/variety-id/${this.varietyId}/states`;
        const breadcrumbs = this.getBrearcrumbs(this.state.variety);
        const variety = this.state.variety;

        if (variety === null) {
            return <Progress />
        }

        const commodity = variety.commodity;
        const mapFunction = (state) => {
            const linkProps = {
                to: Breadcrumbs.getVarietyStateLink(variety.id, commodity.name, variety.name, state.id, state.name),
                label: state.name
            }
            return <NavItem linkProps={linkProps} key={state.id} />
        };

        let categoryId = -1;
        let categoryName = 'All';
        if (commodity.category !== undefined && commodity.category !== null) {
            categoryId = commodity.category.id;
            categoryName = commodity.category.name;
        }

        const nodataUrlFunction = () => Breadcrumbs.getCommodityStatesLink(categoryId, categoryName, commodity.id, commodity.name);

        return <NavCollection mapFunction={mapFunction} resourceUrl={resourceUrl} breadcrumbs={breadcrumbs} nodataUrlFunction={nodataUrlFunction} />
    }
}

export default withRouter(VarietyStates);