import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => {
    return (
        {
            progress: {
                margin: theme.spacing.unit * 2
            },
            root: {
                [theme.breakpoints.down('md')]: {
                    maxWidth: window.screen.width,
                },
                marginTop: theme.spacing.unit * 3,
                overflowX: 'auto',
            },
        })
};

class CommodityPriceTable extends Component {
    render() {
        const { data, hideVarietyName = false, classes } = this.props;
        return (
            <Paper className={classes.root}>
                {data.length > 0 ?
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Date of Arrival
                                    </TableCell>
                                <TableCell>
                                    Max Price (Rs. / Quintal)
                                    </TableCell>
                                <TableCell>
                                    Min Price (Rs. / Quintal)
                                    </TableCell>
                                <TableCell>
                                    Modal Price (Rs. / Quintal)
                                    </TableCell>
                                {hideVarietyName === false ?
                                    <TableCell>
                                        Variety
                                        </TableCell>
                                    :
                                    null
                                }

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.map(priceData => {
                                return (
                                    <TableRow key={priceData.arrivalDate + priceData.variety.name}>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>
                                            {priceData.arrivalDate}
                                        </TableCell>
                                        <TableCell>
                                            {priceData.maxPrice.toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {priceData.minPrice.toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {priceData.modalPrice.toLocaleString()}
                                        </TableCell>
                                        {hideVarietyName === false ?
                                            <TableCell style={{ whiteSpace: "nowrap" }}>
                                                {priceData.variety.name}
                                            </TableCell>
                                            :
                                            null
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    :
                    <div>No matching records found.</div>
                }
            </Paper>
        );
    }
}

export default withStyles(styles)(CommodityPriceTable);