import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import { withRouter } from 'react-router';
import PropTypes  from "prop-types";
import ReactGA from 'react-ga';

function NavItem(props) {
    const { linkProps, history, gaEventArgs } = props;
    return (
        <Card>
            <CardActionArea onClick={() => {
                if (gaEventArgs) {
                    ReactGA.event(gaEventArgs);
                }
                history.push(linkProps.to);
            }}>
                <CardContent>
                    <Typography variant={'h6'} component={'h6'}>
                        {linkProps.label}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

NavItem.propTypes = {
    linkProps : PropTypes.object.isRequired
}

export default withRouter(NavItem);