import axios from 'axios';
import React from 'react';
import { withRouter } from "react-router";
import { BASE_END_POINT } from '../utils/APIConstants';
import { NavCollection, NavItem } from "../Nav";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { Progress } from '../utils';

class VarietyState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variety: null,
            state: null
        };
    }

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        const { stateId, varietyId } = params;
        const varietyResourceUrl = `${BASE_END_POINT}/varieties/${varietyId}`;
        const stateResourceUrl = `${BASE_END_POINT}/states/${stateId}`;
        const getVariety = () => axios.get(varietyResourceUrl);
        const getState = () => axios.get(stateResourceUrl);

        axios.all([getVariety(), getState()]).then(axios.spread(
            (varietyRes, stateRes) => {
                this.setState(
                    {
                        variety: varietyRes.data,
                        state: stateRes.data
                    }
                )
            }
        ));

    }

    getBreadcrumbs(variety, state) {
        if (variety === null || state === null) {
            return [];
        }
        const commodity = variety.commodity;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getVarietyStatesBreadcrumb(variety.id, commodity.name, variety.name),
            Breadcrumbs.getVarietyStateBreadcrumb(variety.id, commodity.name, variety.name, state.id, state.name)
        ];

        return breadcrumbs;
    }

    render() {
        const { match } = this.props;
        const { params } = match;
        const { stateId, varietyId } = params;
        const resourceUrl = `${BASE_END_POINT}/variety-markets/variety-id/${varietyId}/state-id/${stateId}/districts`;
        const variety = this.state.variety;
        const state = this.state.state;

        if (variety === null || state === null) {
            return <Progress />
        }

        const breadcrumbs = this.getBreadcrumbs(this.state.variety, this.state.state);
        const commodity = variety.commodity;

        const mapFunction = (district) => {
            const linkProps = {
                to: Breadcrumbs.getVarietyDistrictLink(variety.id, commodity.name, variety.name, district.id, state.name, district.name),
                label: district.name
            }

            return <NavItem linkProps={linkProps} key={district.id}/>
        }

        return <NavCollection resourceUrl={resourceUrl} mapFunction={mapFunction} breadcrumbs={breadcrumbs} />
    }
}

export default withRouter(VarietyState);