import axios from 'axios';
import React, { Component } from "react";
import { withRouter } from "react-router";
import { BASE_END_POINT } from "../utils/APIConstants";
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getTitle, getDescription, getKeywords } from '../utils/SEOUtils';

class Market extends Component {
    constructor(props) {
        super(props);
        this.state = {
            market: null
        };
    }

    componentDidMount() {
        const { match } = this.props;
        let { marketId } = match.params;
        let { marketName } = match.params;
        let marketResourceUrl = null;
        if (marketId !== undefined && marketId !== null) {
            marketResourceUrl = `${BASE_END_POINT}/markets/${marketId}`;
        }
        if (marketResourceUrl === null && marketName !== undefined && marketName !== null) {
            marketResourceUrl = `${BASE_END_POINT}/marketByName/${encodeURIComponent(marketName)}`;
        }
        axios.get(marketResourceUrl).then(res => {
            this.setState({
                market: res.data
            });
        }).catch(e => {
            console.error(e);
        });
    }

    componentDidUpdate() {
        window.scroll(0, 0);
    }

    getBreadcrumbs(market) {
        if (market == null) {
            return [];
        }
        const district = market.district;
        const state = district.state;
        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getStatesBreadcrumb(),
            Breadcrumbs.getStateBreadcrumb(state.id, state.name),
            Breadcrumbs.getDistrictBreadcrumb(district.id, state.name, district.name),
            Breadcrumbs.getMarketBreadcrumb(market.id, state.name, district.name, market.name)
        ];

        return breadcrumbs;
    }

    render() {
        let resourceUrl = null;
        if (this.state.market !== null) {
            resourceUrl = `${BASE_END_POINT}/commodity-market/marketId/${this.state.market.id}`;
        }
        const mapFunction = (commodityMarket) => {
            const linkProps = {
                to: urlFunction(commodityMarket),
                label: commodityMarket.commodity.name
            };
            return (
                <NavItem linkProps={linkProps} key={commodityMarket.commodity.id} />
            )
        };
        const urlFunction = (commodityMarket) => {
            // return Breadcrumbs.getCommodityPriceLink(commodityMarket.commodity.id, commodityMarket.commodity.name, commodityMarket.market.id, commodityMarket.market.district.state.name, commodityMarket.market.district.name, commodityMarket.market.name);
            const { commodity, market } = commodityMarket;
            const { district } = market;
            const { state } = district;
            return Breadcrumbs.getMarketCommodityLink(market.id, commodity.id, commodity.name, state.name, district.name, market.name);
        }
        const breadcrumbs = this.getBreadcrumbs(this.state.market);
        let marketName = '', districtName = '', stateName = '';
        if (this.state.market != null) {
            marketName = this.state.market.name;
            districtName = this.state.market.district.name;
            stateName = this.state.market.district.state.name;
        }

        return (
            <div>
                {
                    this.state.market != null ?
                    <Helmet>
                        <title>{getTitle(marketName, districtName, stateName)}</title>
                        <meta name="description" content={getDescription(marketName, districtName, stateName)} />
                        <meta name="keywords" content={getKeywords(marketName, districtName, stateName)} />
                    </Helmet>
                    : null
                }
                <NavCollection resourceUrl={resourceUrl} mapFunction={mapFunction} breadcrumbs={breadcrumbs} urlFunction={urlFunction} />
            </div>
        );
    }
}

export default withRouter(Market);