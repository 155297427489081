import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BASE_END_POINT } from '../utils/APIConstants';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { NavCollection, NavItem } from "../Nav";
import { Helmet, getCommodityTitle, getCommodityDescription, getCommodityKeywords, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';

class CommodityMarket extends Component {

    render() {
        const { match } = this.props;
        const { stateId, stateName, districtId, districtName, categoryId, categoryName, commodityId, commodityName, marketId, marketName } = match.params;

        const resourceUrl = `${BASE_END_POINT}/variety-markets/commodity-id/${commodityId}/market-id/${marketId}`;

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName),
            Breadcrumbs.getCommodityStatesBreadcrumb(categoryId, categoryName, commodityId, commodityName),
            Breadcrumbs.getCommodityStateBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName),
            Breadcrumbs.getCommodityDistrictBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName),
            Breadcrumbs.getCommodityMarketBreadcrumb(categoryId, categoryName, commodityId, commodityName, stateId, stateName, districtId, districtName, marketId, marketName)
        ];

        const mapFunction = (varietyMarket) => {
            const linkProps = {
                to: urlFunction(varietyMarket),
                label: varietyMarket.variety.name
            };

            return <NavItem linkProps={linkProps} key={varietyMarket.variety.id} />
        }

        const nodataUrlFunction = () => {
            return Breadcrumbs.getCommodityMarketPriceLink(commodityId, commodityName, marketId, stateName, districtName, marketName);
        }

        const urlFunction = (varietyMarket) => {
            const variety = varietyMarket.variety;
            return Breadcrumbs.getVarietyMarketPriceLink(variety.id, commodityName, variety.name, marketId, stateName, districtName, marketName);
        }

        return (
            <div>
                <Helmet>
                    <title>{getCommodityTitle(commodityName, marketName, districtName, stateName)}</title>
                    <meta name={META_DESCRIPTION} content={getCommodityDescription(commodityName, marketName, districtName, stateName)} />
                    <meta name={META_KEYWORDS} content={getCommodityKeywords(commodityName, marketName, districtName, stateName)} />                    
                </Helmet>
                <NavCollection resourceUrl={resourceUrl} breadcrumbs={breadcrumbs} mapFunction={mapFunction} urlFunction={urlFunction} nodataUrlFunction={nodataUrlFunction} />

            </div>
        );
    }
}

export default withRouter(CommodityMarket);