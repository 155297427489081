import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";

GoogleAnalytics.initialize("UA-48533493-1", {
    // debug: true,
    // user: "selva@anvayin.com"
});

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = (page, location) => {
        GoogleAnalytics.set({
            page,
            location: document.location.href,
            ...options,
        });
        // Since there is a delay in updating the title a small delay is introduced 
        setTimeout(() => { GoogleAnalytics.pageview(page); }, 10);
    };

    // eslint-disable-next-line
    const HOC = class extends Component {
        componentDidMount() {
            // eslint-disable-next-line
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps) {
            const currentPage =
                prevProps.location.pathname + prevProps.location.search;
            const nextPage =
                this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

export default withTracker;