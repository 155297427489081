import React from 'react';
import axios from 'axios';
import debounce from 'debounce-fn';
import isEqual from 'react-fast-compare';

class Axios extends React.Component {
    state = {
        data: undefined,
        loading: false,
        error: false,
        dataLoaded: false
    }

    cancelToken = null;

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate({ children: _, ...prevProps }) {
        const { children, ...props } = this.props
        if (!isEqual(prevProps, props)) {
            this.fetchData()
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken()
        }
    }

    makeNetworkRequestWithDebounce = debounce(() => {
        this.makeNetworkRequest();
    }, 200)

    makeNetworkRequest = () => {
        const { url, method = 'get', params, data, skipRequest = { skip: false } } = this.props

        // Expect to have a minimum of 2 characters before performing search.   
        if (skipRequest.skip === true) {
            this.setState({
                data: skipRequest.defaultReturnValue,
                loading: false,
                error: false,
            })
            return;
        }

        this.setState({
            loading: true
        });

        axios({
            url,
            method,
            params,
            data,
            cancelToken: new axios.CancelToken(token => {
                this.cancelToken = token
            }),
            headers: {
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                this.cancelToken = null
                this.setState({
                    data: res.data,
                    loading: false,
                    error: false,
                    dataLoaded: true
                })
            })
            .catch(e => {
                // Early return if request was cancelled
                if (axios.isCancel(e)) {
                    return
                }
                this.setState({ data: undefined, error: e.message, loading: false })
                console.error(e)
            })
    }

    fetchData = () => {
        if (this.cancelToken) {
            this.cancelToken();
        }

        this.setState({ error: false, loading: true });
        let { withDebounce = false } = this.props;

        if (withDebounce) {
            this.makeNetworkRequestWithDebounce();
        } else {
            this.makeNetworkRequest();
        }
    }

    render() {
        const { children } = this.props
        const { data, loading, error, dataLoaded } = this.state

        return children({
            data,
            loading,
            error,
            dataLoaded,
            refetch: this.fetchData,
        })
    }
}

export default Axios