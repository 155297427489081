import React from 'react';
import { BASE_END_POINT } from '../utils/APIConstants';
import axios from 'axios';
import { withRouter } from 'react-router';

class VarietyPriceMetadata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variety: null,
            market: null
        }
        this.varietyCancelTokenSource = null;
        this.marketCancelTokenSource = null;
        this.varietyId = null;
        this.marketId = null;
    }

    componentDidMount() {
        this.fetchMetadata();
    }

    componentDidUpdate() {
        this.fetchMetadata();
    }

    fetchMetadata() {
        const { match } = this.props;
        const { params } = match;
        const { varietyId, marketId } = params;

        if (this.marketId === marketId && this.varietyId === varietyId) {
            return;
        }

        this.varietyId = varietyId;
        this.marketId = marketId;
        const varietyResourceUrl = `${BASE_END_POINT}/varieties/${varietyId}`;
        const marketResourceUrl = `${BASE_END_POINT}/markets/${marketId}`;

        const varietyCancelToken = axios.CancelToken;
        this.varietyCancelTokenSource = varietyCancelToken.source();

        const marketCancelToken = axios.CancelToken;
        this.marketCancelTokenSource = marketCancelToken.source();

        const getVariety = () => axios.get(varietyResourceUrl, { cancelToken: this.varietyCancelTokenSource.token });
        const getMarket = () => axios.get(marketResourceUrl, { cancelToken: this.marketCancelTokenSource.token });

        axios.all([getVariety(), getMarket()])
            .then(axios.spread((varietyRes, marketRes) => {
                this.varietyCancelTokenSource = null;
                this.marketCancelTokenSource = null
                this.setState({
                    variety: varietyRes.data,
                    market: marketRes.data
                });
            })).catch(e => {
                if (axios.isCancel(e)) {
                    return
                }
            });        
    }

    componentWillUnmount() {
        if (this.varietyCancelTokenSource !== null) {
            this.varietyCancelTokenSource.cancel();
        }
        if (this.marketCancelTokenSource !== null) {
            this.marketCancelTokenSource.cancel();
        }
    }

    render() {
        const { children } = this.props;
        return children({ variety: this.state.variety, market: this.state.market });
    }
}

export default withRouter(VarietyPriceMetadata);