import React from 'react';
import { NavItem } from "../Nav";
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import * as Breadcrumbs from '../Nav/Breadcrumbs';
import { BASE_END_POINT } from '../utils/APIConstants';
import axios from 'axios';

class AdjacentVarietyMarket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            varietyMarkets: []
        }
    }

    getLinkProps(varietyMarket) {
        const variety = varietyMarket.variety;
        const commodity = varietyMarket.variety.commodity;
        const market = varietyMarket.market;
        // Breadcrumbs.getCommodityMarketPriceLink(commodity.id, commodity.name, market.id, market.district.state.name, market.district.name, market.name),

        const linkProps = {
            to: Breadcrumbs.getVarietyMarketPriceLink(variety.id, commodity.name, variety.name, market.id, market.district.state.name, market.district.name, market.name),
            label: `${market.name}, ${market.district.name === market.name ? '' : market.district.name + ','} ${market.district.state.name}`
        };
        return linkProps;
    }

    componentDidMount() {
        this.fetchVarietyMarkets();
    }

    componentDidUpdate(prevProps, prevState) {
        const { variety, market } = this.props;
        const { variety: prevVariety, market: prevMarket } = prevProps;
        if (prevVariety !== undefined && prevMarket !== undefined && prevVariety.id === variety.id && prevMarket.id === market.id) {
            return;
        }
        this.fetchVarietyMarkets();
    }

    cancelToken = null;

    fetchVarietyMarkets() {
        const { variety, market } = this.props;
        const url = `${BASE_END_POINT}/_search/adjacent-variety-markets?varietyId=${variety.id}&marketId=${market.id}`;

        const config = {
            cancelToken: new axios.CancelToken(cancelToken =>
                this.cancelToken = cancelToken
            )
        };

        axios.get(url, config).then(res => {
            this.setState({
                varietyMarkets: res.data
            });
        }).catch(err => {
            // console.log(err);
        });
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken();
        }
    }

    render() {
        const { variety } = this.props;
        const { varietyMarkets } = this.state;

        const items = varietyMarkets.map((varietyMarket, index) => {
            const linkProps = this.getLinkProps(varietyMarket);
            if (linkProps === null) {
                return null;
            }
            /*
            const gaEventArgs = {
                category: 'Other Nearby Markets',
                action: `Clicked '${linkProps.label}'.`
            }
            return <NavItem linkProps={linkProps} key={`${varietyMarket.id}`} gaEventArgs={gaEventArgs} />;
            */
           return <NavItem linkProps={linkProps} key={`${varietyMarket.id}`}/>;
        });

        return (
            <Grid container direction={'column'} spacing={8} alignItems={'center'} justify={'center'} style={{ marginTop: 32 }}>
                <Grid item>
                    <Typography variant={"h5"} component={"h5"}>{`Other Nearby Markets for ${variety.commodity.name} (${variety.name})`}</Typography>
                </Grid>

                <Grid item>
                    {items}
                </Grid>
            </Grid>
        )
    }
}

export default AdjacentVarietyMarket;