import React, { Component } from 'react';
import { AUTH_STATE_NOT_SIGNED_IN, AUTH_STATE_NOT_YET_LOADED, BASE_END_POINT } from '../utils/APIConstants';
import axios from 'axios';
import { withAuthUser, getConfigWithAuthHeader } from '../Auth';
import SellAdCard from './SellAdCard';
import { Grid, Typography } from '@material-ui/core';

class SellAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellAds: null
        };
        this.repositoryDataLoaded = false;
        this.loadingRepositoryData = false;
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        const { authUser } = this.props;
        if (authUser !== AUTH_STATE_NOT_SIGNED_IN || authUser !== AUTH_STATE_NOT_YET_LOADED) {
            this.getAdsOfCurrentUser();
        }
    }

    getAdsOfCurrentUser = async () => {
        if (this.loadingRepositoryData === false) {
            this.loadingRepositoryData = true;

            const { authUser } = this.props;
            const config = await getConfigWithAuthHeader(authUser);

            const resourceUrl = `${BASE_END_POINT}/sell-ads/current-user`;
            axios.get(resourceUrl, config).then(res => {
                // this.setStateFromResponse(res);
                console.log(res.data);
                this.setState({
                    sellAds: res.data
                })
                this.repositoryDataLoaded = true;
                this.loadingRepositoryData = false;
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        // throw Error ('Error from Sell Ad. ');
        if (this.state.sellAds == null) {
            return null;
        }

        return <Grid container direction={'column'}>
            <Grid item style={{ alignSelf: 'center' }}>
                <Typography variant={'h5'}>Sell Listings</Typography>
            </Grid>

            {
                this.state.sellAds.map(sellAd => {
                    if (sellAd.variety == null || sellAd.market == null) {
                        return null;
                    } else {
                        return (
                            <Grid item key={sellAd.id}>
                                <SellAdCard sellAd={sellAd} />
                            </Grid>
                        );
                    }
                }
                )
            }
        </Grid>

    }
}

export default withAuthUser(SellAd);