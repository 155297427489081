import React, { Component } from 'react';
import AdSense from 'react-adsense';
import ErrorBoundry from './ErrorBoundry';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => {
    return ({
        container: {
            [theme.breakpoints.down('sm')]: {
                minWidth: window.screen.availWidth
            },
            [theme.breakpoints.up('md')]: {
                minWidth: window.screen.availWidth * .5
            }
        },
        close: {
            padding: theme.spacing.unit / 2,
        }
    })
}


class AdSlot extends Component {
    render() {
        const { classes } = this.props;
        const { slotLocation } = this.props;
        return (
            <ErrorBoundry parentComponentName={`AdSlot-${slotLocation}`}>
                <div className={classes.container}>
                    <AdSense.Google client='ca-pub-7539956430725876' {...this.props} />
                </div>
            </ErrorBoundry>
        )
    }
}

export default withStyles(styles)(AdSlot);