import { Grid } from '@material-ui/core';
import React from 'react';
import { AdjacentCommodityMarket, CommodityPrice, CommodityPriceMetadata } from "../CommodityPrice";
import * as Breadcrumbs from "../Nav/Breadcrumbs";
import { NewsContainer } from '../News';
import { AdSlot } from '../utils';
import { BASE_END_POINT } from '../utils/APIConstants';
import { getCommodityDescription, getCommodityKeywords, getCommodityTitle, Helmet, META_DESCRIPTION, META_KEYWORDS } from '../utils/SEOUtils';
import { viewedCommodity } from '../utils/SubscriptionUtils';


class CommodityMarketPrice extends React.Component {
    componentWillUpdate() {

    }

    componentDidUpdate() {
    }

    getBreadcrumbs(commodity, market) {
        if (market === null || commodity === null) {
            return [];
        }

        const district = market.district;
        const state = district.state;

        let categoryId = -1;
        let categoryName = 'All';
        if (commodity.category !== undefined && commodity.category !== null) {
            categoryId = commodity.category.id;
            categoryName = commodity.category.name;
        }

        const breadcrumbs = [
            Breadcrumbs.getHomeBreadcrumb(),
            Breadcrumbs.getCategoriesBreadcrumb(),
            Breadcrumbs.getCategoryCommoditiesBreadcrumb(categoryId, categoryName),
            Breadcrumbs.getCommodityStatesBreadcrumb(categoryId, categoryName, commodity.id, commodity.name),
            Breadcrumbs.getCommodityStateBreadcrumb(categoryId, categoryName, commodity.id, commodity.name, state.id, state.name),
            Breadcrumbs.getCommodityDistrictBreadcrumb(categoryId, categoryName, commodity.id, commodity.name, state.id, state.name, district.id, district.name),
            // commodityId, commodityName, marketId, stateName, districtName, marketName
            Breadcrumbs.getCommodityMarketPriceBreadcrumb(commodity.id, commodity.name, market.id, state.name, district.name, market.name)
        ];

        return breadcrumbs;
    }

    render() {
        return (
            <CommodityPriceMetadata>
                {({ commodity, market }) => {
                    const breadcrumbs = this.getBreadcrumbs(commodity, market);
                    let resourceUrl = null;
                    let title = null;
                    let loading = true;
                    let commodityName = null, marketName = null, districtName = null, stateName = null;;

                    if (commodity !== null && market !== null) {
                        commodityName = commodity.name;
                        marketName = market.name;
                        districtName = market.district.name;
                        stateName = market.district.state.name;

                        resourceUrl = `${BASE_END_POINT}/commodity-prices/commodityId/${commodity.id}/marketId/${market.id}`;
                        title = `${commodity.name} Prices at ${market.name}`;
                        loading = false;
                        viewedCommodity(market.id, commodity.id, marketName, districtName, stateName, commodityName);
                    }
                    return (
                        <div style={{ padding: 8 }}>
                            <Helmet>
                                <title>{getCommodityTitle(commodityName, marketName, districtName, stateName)}</title>
                                <meta name={META_DESCRIPTION} content={getCommodityDescription(commodityName, marketName, districtName, stateName)} />
                                <meta name={META_KEYWORDS} content={getCommodityKeywords(commodityName, marketName, districtName, stateName)} />
                            </Helmet>

                            <Grid container direction={"column"} spacing={16}>
                                <Grid item>
                                    <Breadcrumbs.default breadcrumbs={breadcrumbs} />
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    <CommodityPrice loading={loading} resourceUrl={resourceUrl} title={title} commodity={commodity} market={market} />
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    {
                                        commodity != null && market != null ?
                                            <AdSlot slot='1221645509' responsive='true' key={commodity != null && market != null ? `${commodity.id}-${market.id}-1` : `marketCommodityPrice-1`} slotLocation={`CommodityMarketPrice-AfterCommodityPrice`} />
                                            :
                                            null
                                    }
                                </Grid>
                                <Grid item md={12} lg={6}>
                                    {commodity != null && market != null ?
                                        <AdjacentCommodityMarket commodity={commodity} market={market} />
                                        :
                                        null
                                    }
                                </Grid>
                                {/** 
                                <Grid item md={12} lg={6}>
                                    {
                                        commodity != null && market != null ?
                                            <AdSlot slot='6667872287' format='autorelaxed' key={commodity != null && market != null ? `${commodity.id}-${market.id}-2` : `commodityMarketPrice-2`} slotLocation={`CommodityMarketPrice-AfterAdjacentCommodityMarket`} />
                                            :
                                            null
                                    }
                                </Grid>
                                */}
                                <Grid item md={12} lg={6}>
                                    {commodityName != null ?
                                        <NewsContainer commodityName={commodity.name} />
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                        </div>

                    );
                }
                }
            </CommodityPriceMetadata>
        );
    }
}


export default CommodityMarketPrice;