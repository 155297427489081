import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import Axios from './axios';
import * as Breadcrumbs from './components/Nav/Breadcrumbs';
import { BASE_END_POINT } from './components/utils/APIConstants';
import { Helmet, BASE_TITLE, BASE_DESCRIPTION, BASE_KEYWORDS } from './components/utils/SEOUtils';
import { RecentlyViewed } from './components/User'

const endpoint = BASE_END_POINT + '/_search';
const TYPE_VARIETY = 'Variety';
const TYPE_COMMODITY = 'Commodity';
const TYPE_MARKET = 'Market';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    menuWidth: {
        minWidth: 320
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});


class Home extends Component {
    constructor(props) {
        super(props);
        this.downshiftRef = React.createRef();
        this.inputRef = React.createRef();
        this.state = {
            "selectedItem": null,
            "selectedItemType": null
        };
    }
    componentDidMount() {
        const page = this.props.location.pathname;
        ReactGA.set({ page })
        ReactGA.pageview(page);
    }
    downshifOnSelect = (selectedItem) => {
        this.setState({ "selectedItem": selectedItem });
    };

    handleSearch = () => {
        // TODO: Indicate that an item has to be selected.
        if (this.state.selectedItem === null) {
            return;
        }

        ReactGA.event({
            category: 'Search',
            action: `Selected '${this.state.selectedItem.name}' and Search Button Clicked.`
        });

        const { history } = this.props;
        if (this.state.selectedItem.type === TYPE_MARKET) {
            const market = this.state.selectedItem;
            history.push(Breadcrumbs.getMarketLink(market.id, market.district.state.name, market.district.name, market.name));
        } else if (this.state.selectedItem.type === TYPE_COMMODITY) {
            const commodity = this.state.selectedItem;

            let categoryId = -1;
            let categoryName = 'All';
            if (commodity.category !== undefined && commodity.category !== null) {
                categoryId = commodity.category.id;
                categoryName = commodity.category.name;
            }

            history.push(Breadcrumbs.getCommodityStatesLink(categoryId, categoryName, commodity.id, commodity.name));
        } else if (this.state.selectedItem.type === TYPE_VARIETY) {
            const variety = this.state.selectedItem;
            const commodity = variety.commodity;
            history.push(Breadcrumbs.getVarietyStatesLink(variety.id, commodity.name, variety.name));
        }
    }

    /*
    sendGAEvent = (inputValue) => {
        if (inputValue === null || inputValue === undefined || inputValue === '') {
            return;
        }
        ReactGA.event({
            category: 'Search',
            action: `Search Performed for '${inputValue}'.`
        });
    }
    */

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Helmet>
                    <title>{BASE_TITLE}</title>
                    <meta name="description" content={BASE_DESCRIPTION} />
                    <meta name="keywords" content={BASE_KEYWORDS} />
                </Helmet>

                <Grid container direction={'column'} alignItems={'center'} justify={'center'} spacing={16}>
                    <Grid item>
                        <Grid container direction={'column'} spacing={32}>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">Select Commodity or Market</Typography>
                    </Grid>
                    <Grid item>
                        <Downshift itemToString={i => (i == null ? '' : i.name)} defaultHighlightedIndex={0}
                            onOuterClick={(downshift) => {
                                /* 
                                console.log(this); 
                                console.log(downshift); 
                                this.downshiftRef.current.selectItemAtIndex(downshift.highlightedIndex);
                                */
                            }
                            }
                            ref={this.downshiftRef}
                            onSelect={this.downshifOnSelect}
                        >
                            {({
                                getInputProps,
                                getItemProps,
                                getMenuProps,
                                highlightedIndex,
                                inputValue,
                                isOpen,
                                selectedItem,
                            }) => {
                                // this.sendGAEvent(inputValue);
                                return (
                                    <div className={classes.container}>
                                        {renderInput({
                                            fullWidth: true,
                                            classes,
                                            InputProps: getInputProps({
                                                placeholder: 'Search market: e.g. Madathukulam'
                                            }),
                                            ref: node => {
                                                this.inputRef = node;
                                            }
                                        })}
                                        <Popper open={isOpen} anchorEl={this.inputRef}>
                                            <div {...getMenuProps({}, { suppressRefError: true })} className={classes.menuWidth}>
                                                {isOpen ? (
                                                    <Axios url={endpoint} params={{ query: deburr(inputValue) }}
                                                        skipRequest={{ skip: deburr(inputValue).length < 3, defaultReturnValue: [] }}
                                                        withDebounce={true}>
                                                        {({ loading, error, data = {} }) => {
                                                            const markets = data.markets;
                                                            const commodities = data.commodities;
                                                            const varieties = data.varieties;
                                                            const marketsLength = markets === undefined ? 0 : markets.length;
                                                            const commoditiesLength = commodities === undefined ? 0 : commodities.length;
                                                            return <Paper className={classes.paper} square>
                                                                {
                                                                    markets !== undefined ? markets.map((dataItem, index) => {
                                                                        const item = dataItem;
                                                                        item.type = TYPE_MARKET;
                                                                        return renderMarketItem({
                                                                            id: dataItem.id,
                                                                            item,
                                                                            index,
                                                                            itemProps: getItemProps({ item: item }),
                                                                            highlightedIndex,
                                                                            selectedItem,
                                                                        })
                                                                    }, this) : null
                                                                }
                                                                {
                                                                    commodities !== undefined ? commodities.map((dataItem, index) => {
                                                                        const item = dataItem;
                                                                        item.type = TYPE_COMMODITY;
                                                                        index += marketsLength;
                                                                        return renderMarketItem({
                                                                            id: dataItem.id,
                                                                            item,
                                                                            index,
                                                                            itemProps: getItemProps({ item: item }),
                                                                            highlightedIndex,
                                                                            selectedItem,
                                                                        })
                                                                    }, this) : null
                                                                }
                                                                {
                                                                    varieties !== undefined ? varieties.map((dataItem, index) => {
                                                                        const item = dataItem;
                                                                        item.type = TYPE_VARIETY;
                                                                        index += (marketsLength + commoditiesLength);
                                                                        return renderMarketItem({
                                                                            id: dataItem.id,
                                                                            item,
                                                                            index,
                                                                            itemProps: getItemProps({ item: item }),
                                                                            highlightedIndex,
                                                                            selectedItem,
                                                                        })
                                                                    }, this) : null
                                                                }

                                                            </Paper>
                                                        }}
                                                    </Axios>
                                                ) : null}
                                            </div>
                                        </Popper>
                                    </div>
                                )
                            }}
                        </Downshift>
                    </Grid>

                    <Grid item >
                        <Button variant="contained" color="primary" onClick={this.handleSearch}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item>
                        <RecentlyViewed />
                    </Grid>
                </Grid>
            </div>

        );
    }
}

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}

function renderMarketItem({ id, item, index, itemProps, highlightedIndex, selectedItem, type }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === item;

    let displayString;
    if (item.type === TYPE_MARKET) {
        displayString = `${item.name} - ${item.district.name}, ${item.district.state.name}`;
    } else if (item.type === TYPE_COMMODITY) {
        displayString = `${item.name}`;
    } else if (item.type === TYPE_VARIETY) {
        displayString = `${item.name} - ${item.commodity.name}`;
    }

    return (
        <MenuItem
            {...itemProps}
            key={id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {displayString}
        </MenuItem>
    );
}

renderMarketItem.propTypes = {
    id: PropTypes.number,
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    selectedItem: PropTypes.string,
    item: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Home));