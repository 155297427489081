import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';

class NewsItem extends Component {
    render() {
        const { rssFeed } = this.props;
        let description = rssFeed.description.trim();
        /*
        if (description && description.length > 0 && !description.endsWith(".")) {
            description = description + "...";
        }
        */

        const handleReadMoreClick = () => {
            window.open(rssFeed.link, rssFeed.title);
        };

        return (
            <Card>
                <CardContent style={{paddingBottom:0}}>
                    <Typography variant={"subtitle1"} color="textSecondary">{rssFeed.date}</Typography>
                    <Typography variant={"subtitle1"} color="textSecondary">{rssFeed.publisher}</Typography>
                    <Typography variant={"h6"} component={"h6"}>{rssFeed.title}</Typography>
                    <Typography variant={"body1"} >{description}</Typography>
                </CardContent>
                <CardActions disableActionSpacing={true} style={{justifyContent:"flex-end"}}>
                    <Button size="small" onClick={handleReadMoreClick}>Read More...</Button>
                </CardActions>
            </Card>
        );
    }
}

export default NewsItem;