const PRICE_META_DATA_KEY = "PriceMetadata";

export const TOTAL_NO_OF_FREE_VIEWS = 10;
export const TOTAL_NO_OF_FREE_SESSION_VIEWS = 3;

let noOfSessionViews = 0;
// TODO: Implement a per day limit. i.e. a date based no of views. 
// Once a certain no of views reached on a given day, prompt for subscription.
// Also handle incognito mode. Is it possible to detect it?

export const resetSessionViews = () => {
    noOfSessionViews = 0;
}

export const incrementSessionViews = () => {
    noOfSessionViews++;
}

export const incrementViews = () => {
    incrementSessionViews();
};


export const getTotalViewsInThisSession = () => {
    return noOfSessionViews;
}

export const isphoneNumberValid = (phoneNumber) => {
    // Other Regular Expression: /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/i
    const valid = /^\d{10}$/.test(phoneNumber);
    return valid;
}

export const getPhoneNoWithoutCountryCode = (phoneNumber) => {
    if (phoneNumber == null) {
        return phoneNumber;
    }
    if (phoneNumber.startsWith('+91')) {
        phoneNumber = phoneNumber.substring(3);
    }
    return phoneNumber;
}

export const isEmailValid = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());
    return valid;
}

export const viewedCommodity = (marketId, commodityId, marketName, districtName, stateName, commodityName) => {
    const priceMetadata = getCommodityPriceMetadata(marketId, commodityId, marketName, districtName, stateName, commodityName);
    viewedPrice(priceMetadata);
}

export const viewedVariety = (marketId, varietyId, marketName, districtName, stateName, varietyName, commodityName) => {
    const priceMetadata = getVarietyPriceMetadata(marketId, varietyId, marketName, districtName, stateName, varietyName, commodityName);
    viewedPrice(priceMetadata);
}

let priceMetadataArray = null;

if (localStorage) {
    priceMetadataArray = JSON.parse(localStorage.getItem(PRICE_META_DATA_KEY));
}

if (priceMetadataArray === null) {
    priceMetadataArray = [];
}

export const viewedPrice = (priceMetadata) => {
    const arrayLength = priceMetadataArray.length
    if (arrayLength > MAX_ENTRIES) {
        const noOfItemsToSlice = arrayLength - MAX_ENTRIES;
        priceMetadataArray = priceMetadataArray.slice(noOfItemsToSlice);
    }
    // Remove the currently viewed item if it already exists.
    priceMetadataArray = priceMetadataArray.filter(item => {
        if ((item.type === priceMetadata.type) && (item.marketId === priceMetadata.marketId) && (item.itemId === priceMetadata.itemId)) {
            return false;
        } else {
            return true;
        }
    });
    // Add the item to the top of the Array, so that it's the one that's retruned when asked for the top Item.
    priceMetadataArray.push(priceMetadata);
    if (localStorage) {
        localStorage.setItem(PRICE_META_DATA_KEY, JSON.stringify(priceMetadataArray));
    }
}

export const TYPE_COMMODITY = 'Commodity';
export const TYPE_VARIETY = 'Variety';
const MAX_ENTRIES = 15;

export const getCommodityPriceMetadata = (marketId, commodityId, marketName, districtName, stateName, commodityName) => {
    return {
        type: TYPE_COMMODITY,
        marketId: marketId,
        itemId: commodityId,
        marketName: marketName,
        districtName: districtName,
        stateName: stateName,
        commodityName: commodityName
    }
}

export const getVarietyPriceMetadata = (marketId, varietyId, marketName, districtName, stateName, varietyName, commodityName) => {
    return {
        type: TYPE_VARIETY,
        marketId: marketId,
        itemId: varietyId,
        marketName: marketName,
        districtName: districtName,
        stateName: stateName,
        varietyName: varietyName,
        commodityName: commodityName
    }
}

export const getRecentlyViewedPriceMetadataArray = () => {
    if (localStorage) {
        const priceMetadataArrayStr = localStorage.getItem(PRICE_META_DATA_KEY);
        if (priceMetadataArrayStr === null) {
            return null;
        }
        priceMetadataArray = JSON.parse(priceMetadataArrayStr);
    }
    return priceMetadataArray.reverse();
}